import React, { useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import MapInit from "./MapInit";
import MapData from "./MapData";
import CircularProgress from "@mui/material/CircularProgress";
import { SelectedDatasetValue } from "../filter-container/filters/ControlledDataPointsRadioButtonsGroup";
import styled from "@mui/material/styles/styled";
import MapLegend from "./MapLegend";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import IRMapperLogo from "./IRMapperLogo";
import { FeatureCollection, Geometry, GeoJsonProperties } from "geojson";
import MapSpeedDial from "./MapSpeedDial";
import { MosquitoGenus } from "../../utils/mapUtils";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const MapComponent = styled("div")({
  position: "relative",
  height: "100%",
  display: "flex",
  margin: "0",
  padding: "0",
});

const MapContainer = styled("div")({
  flexGrow: "1",
});

interface IRMapProps {
  mosquitoGenus: MosquitoGenus;
  selectedDatasetValue: SelectedDatasetValue;
  collectionYearRange: number[];
  onCountryOptionsChange: (countryOptions: string[]) => void;
  selectedCountries: string[];
  onVectorSpeciesOptionsChange: (vectorSpeciesOptions: string[]) => void;
  selectedVectorSpecies: string[];
  onVectorDevStageOptionsChange: (vectorDevStageOptions: string[]) => void;
  selectedVectorDevStage: string[];
  testMethods: string[];
  onInsecticideClassOptionsChange: (insecticideClassOptions: string[]) => void;
  selectedInsecticideClass: string[];
  onInsecticideTypeOptionsChange: (
    insecticideTypeOptions: Record<string, string[]>
  ) => void;
  selectedInsecticideType: string[];
  onIRACMoAOptionsChange: (IRACMoAOptions: string[]) => void;
  selectedIRACMoA: string[];
  onIRMechanismNameOptionsChange: (IRMechanismNameOptions: string[]) => void;
  selectedIRMechanismName: string[];
}

export default function IRMap(props: IRMapProps) {
  const mapContainer = useRef(null);
  const map = useRef<mapboxgl.Map | null>(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);

  const initialGeoJsonCustomData: FeatureCollection<
    Geometry,
    GeoJsonProperties
  > = {
    type: "FeatureCollection",
    features: [],
  };
  const [geoJsonCustomData, setGeoJsonCustomData] = useState<
    FeatureCollection<Geometry, GeoJsonProperties>
  >(initialGeoJsonCustomData);
  const clearGeoJsonCustomData = () => {
    setGeoJsonCustomData(initialGeoJsonCustomData);
  };

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const handleCloseSnackbar = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const [selectedLayer, setSelectedLayer] = useState<string | null>(null);

  return (
    <MapComponent
      aria-describedby="map-loading"
      aria-busy={mapLoaded && !dataLoaded}
    >
      <Backdrop
        open={mapLoaded && !dataLoaded}
        component="div"
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              position: "relative",
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress
              variant="determinate"
              color="inherit"
              aria-label="map-loading"
              value={downloadProgress}
              sx={{
                color: "#fff",
              }}
            />
            <Box
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="caption"
                component="div"
                color="inherit"
              >{`${Math.round(downloadProgress)}%`}</Typography>
            </Box>
          </Box>
          <Typography
            variant="body1"
            component="div"
            color="inherit"
            sx={{ mt: 2 }} // Add margin top for spacing
          >
            Data loading...
          </Typography>
        </Box>
      </Backdrop>
      <MapSpeedDial
        dataLoaded={dataLoaded}
        mosquitoGenus={props.mosquitoGenus}
        geoJsonCustomData={geoJsonCustomData}
        setGeoJsonCustomData={setGeoJsonCustomData}
        clearGeoJsonCustomData={clearGeoJsonCustomData}
        setErrorMessage={setErrorMessage}
        setOpenSnackbar={setOpenSnackbar}
        map={map}
        selectedLayer={selectedLayer}
        setSelectedLayer={setSelectedLayer}
      />
      <MapLegend
        selectedDatasetValue={props.selectedDatasetValue}
        selectedLayer={selectedLayer}
      />
      <MapContainer ref={mapContainer}>
        <MapInit
          mapContainer={mapContainer}
          map={map}
          mosquitoGenus={props.mosquitoGenus}
        />
        <MapData
          map={map}
          mosquitoGenus={props.mosquitoGenus}
          selectedDatasetValue={props.selectedDatasetValue}
          collectionYearRange={props.collectionYearRange}
          onCountryOptionsChange={props.onCountryOptionsChange}
          selectedCountries={props.selectedCountries}
          onVectorSpeciesOptionsChange={props.onVectorSpeciesOptionsChange}
          selectedVectorSpecies={props.selectedVectorSpecies}
          onVectorDevStageOptionsChange={props.onVectorDevStageOptionsChange}
          selectedVectorDevStage={props.selectedVectorDevStage}
          testMethods={props.testMethods}
          onInsecticideClassOptionsChange={
            props.onInsecticideClassOptionsChange
          }
          selectedInsecticideClass={props.selectedInsecticideClass}
          onInsecticideTypeOptionsChange={props.onInsecticideTypeOptionsChange}
          selectedInsecticideType={props.selectedInsecticideType}
          onIRACMoAOptionsChange={props.onIRACMoAOptionsChange}
          selectedIRACMoA={props.selectedIRACMoA}
          onIRMechanismNameOptionsChange={props.onIRMechanismNameOptionsChange}
          selectedIRMechanismName={props.selectedIRMechanismName}
          setMapLoaded={setMapLoaded}
          setDataLoaded={setDataLoaded}
          geoJsonCustomData={geoJsonCustomData}
          setDownloadProgress={setDownloadProgress}
        />
      </MapContainer>
      <IRMapperLogo />
      <Snackbar open={openSnackbar} onClose={handleCloseSnackbar}>
        <Alert
          onClose={handleCloseSnackbar}
          severity="error"
          sx={{ width: "100%" }}
        >
          <AlertTitle>Error with custom data upload</AlertTitle>
          {errorMessage}
        </Alert>
      </Snackbar>
    </MapComponent>
  );
}
