import * as React from "react";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";

interface IRACMoAFilterProps {
  IRACMoAOptions: string[];
  IRACMoA: string[];
  onIRACMoAChange: (newIRACMoA: string[]) => void;
  onFilterChange?: () => void;
}

export default function IRACMoAFilter({
  IRACMoAOptions,
  IRACMoA,
  onIRACMoAChange,
  onFilterChange,
}: IRACMoAFilterProps) {
  const { t } = useTranslation();

  const handleChange = (newValue: string | string[]) => {
    onIRACMoAChange(newValue as string[]);
  };

  return (
    <FormControl fullWidth>
      <FormLabel id="IRAC-MoA-group" sx={{ paddingBottom: 2 }}>
        {t("mapPage.filterDrawer.IRACMoA.title")}
      </FormLabel>
      <Box>
        <Autocomplete
          fullWidth={true}
          multiple
          id="IRAC-MoA-filter"
          value={IRACMoA}
          onChange={(event, newValue) => {
            handleChange(newValue);
            if (onFilterChange) {
              onFilterChange();
            }
          }}
          options={IRACMoAOptions.filter(
            (option) => option !== "NA" && option !== "Na"
          ).sort((a, b) => {
            const order = [
              "1",
              "1A",
              "1B",
              "2",
              "2A",
              "2B",
              "3",
              "3A",
              "3B",
              "4",
              "4A",
              "4B",
              "4C",
              "4D",
              "4E",
              "4F",
              "5",
              "6",
              "7",
              "7A",
              "7B",
              "7C",
              "8",
              "8A",
              "8B",
              "8C",
              "8D",
              "8E",
              "8F",
              "9",
              "9B",
              "9D",
              "10",
              "10A",
              "10B",
              "11",
              "11A",
              "11B",
              "12",
              "12A",
              "12B",
              "12C",
              "12D",
              "13",
              "14",
              "15",
              "16",
              "17",
              "18",
              "19",
              "20",
              "20A",
              "20B",
              "20C",
              "20D",
              "21",
              "21A",
              "21B",
              "22",
              "22A",
              "22B",
              "23",
              "24",
              "24A",
              "24B",
              "25",
              "25A",
              "25B",
              "28",
              "29",
              "30",
              "31",
              "32",
              "33",
              "34",
              "35",
              "36",
              "UN*",
              "UNB*",
              "UNE*",
              "UNF*",
              "UNM*",
              "UNP*",
              "UNV*",
            ];
            const indexA = order.indexOf(a);
            const indexB = order.indexOf(b);
            if (indexA === -1) return 1; // a comes after b
            if (indexB === -1) return -1; // a comes before b
            return indexA - indexB; // compare based on order
          })}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip label={option} {...getTagProps({ index })} />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("mapPage.filterDrawer.IRACMoA.title")}
            />
          )}
        />
      </Box>
    </FormControl>
  );
}
