import * as React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { alpha } from "@mui/material";
import { useTranslation } from "react-i18next";

interface DrawerTitleProps {
  toggleDrawer: (newOpen: boolean) => () => void;
}

const DrawerTitle: React.FC<DrawerTitleProps> = ({ toggleDrawer }) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        width: 396,
        height: 64,
        bgcolor: "primary.main",
        px: 2,
        py: "10px",
      }}
    >
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"flex-start"}
          spacing={1}
        >
          <FilterAltOutlinedIcon sx={{ color: alpha("#ffffff", 0.54) }} />
          <Typography color={"white"} variant="subtitle1">
            {t("mapPage.filterDrawerTitle")}
          </Typography>
        </Stack>
        <IconButton
          aria-label="close"
          onClick={toggleDrawer(false)}
          sx={{ color: alpha("#ffffff", 0.54) }}
        >
          <CloseOutlinedIcon />
        </IconButton>
      </Stack>
    </Box>
  );
};

export default DrawerTitle;
