import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";

const HeroSection = () => {
  const { t } = useTranslation();

  return (
    <Stack
      borderRadius={"4px"}
      paddingX={4}
      paddingTop={18}
      paddingBottom={8}
      spacing={5}
      alignItems={"center"}
      justifyContent={"flex-start"}
      alignSelf={"stretch"}
      sx={{
        position: "relative",
        backgroundImage: `url(/IRMapper_Cover.png)`,
        backgroundColor: "lightgray",
        backgroundSize: "cover",
        backgroundPosition: "50%",
        backgroundRepeat: "no-repeat",
        "&::before": {
          content: '""',
          borderRadius: "4px",
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "#821217",
          mixBlendMode: "multiply",
          zIndex: 1,
        },
        "& > *": {
          position: "relative",
          zIndex: 2,
        },
      }}
    >
      <Stack spacing={3} alignItems={"center"} justifyContent={"flex-start"}>
        <Stack>
          <Typography color={"white"} textAlign={"center"} variant="h2">
            {t("homePage.hero.welcome")}
          </Typography>
          <Typography color={"white"} textAlign={"center"} variant="h2">
            IR Mapper
          </Typography>
        </Stack>
        <Typography
          color={"white"}
          textAlign={"center"}
          maxWidth={"570px"}
          variant="subtitle1"
        >
          {t("homePage.hero.subtitle")}
        </Typography>
      </Stack>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          alignContent: "center",
          gap: "24px",
          flexWrap: "wrap",
          alignSelf: "stretch",
        }}
      >
        <Button
          sx={{ width: "222px" }}
          variant="contained"
          color="primary"
          href="/anopheles"
        >
          {t("homePage.hero.anophelesButton")}
        </Button>
        <Button
          sx={{ width: "222px" }}
          variant="contained"
          color="primary"
          href="/aedes"
        >
          {t("homePage.hero.aedesButton")}
        </Button>
      </Box>
    </Stack>
  );
};

export default HeroSection;
