import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { SelectedDatasetValue } from "../filter-container/filters/ControlledDataPointsRadioButtonsGroup";
import { useTranslation } from "react-i18next";
import { grey } from "@mui/material/colors";
import { alpha } from "@mui/material/styles";

interface MapLegendProps {
  selectedDatasetValue: SelectedDatasetValue;
  selectedLayer: string | null;
}

interface MarkerProps {
  color: string;
}

interface MarkerLegendProps {
  color: string;
  label: string;
}

interface DataRangeLegendProps {
  colorRange: string[];
  dataRange: string[];
  label: string;
}

const Marker: React.FC<MarkerProps> = ({ color }) => {
  return (
    <Box height={16} width={16}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <circle cx="8" cy="8" r="5" fill={color} />
      </svg>
    </Box>
  );
};

export const MarkerLegend: React.FC<MarkerLegendProps> = ({ color, label }) => {
  return (
    <Stack direction="row" spacing={1} alignItems={"center"}>
      <Marker color={color} />
      <Typography variant="legend">{label}</Typography>
    </Stack>
  );
};

export const DataRangeLegend: React.FC<DataRangeLegendProps> = ({
  colorRange,
  dataRange,
  label,
}) => {
  return (
    <Stack direction="column" spacing={1} alignItems={"center"} paddingTop={3}>
      <Typography variant="legendTitle">{label}</Typography>
      <Box
        height={16}
        width="100%"
        sx={{
          background: `linear-gradient(to right, ${colorRange.join(", ")})`,
        }}
      />
      <Box display="flex" justifyContent="space-between" width="100%">
        {dataRange.map((value: string, index: number) => (
          <Typography key={index} variant="caption">
            {value}
          </Typography>
        ))}
      </Box>
    </Stack>
  );
};

export default function MapLegend(props: MapLegendProps) {
  const { t } = useTranslation();

  return (
    <Box
      width={240}
      position="absolute"
      bottom={80}
      right={10}
      sx={{ zIndex: 1040 }}
    >
      <Card
        elevation={4}
        sx={{
          bgcolor: alpha(grey.A100, 0),
          border: 0.1,
          borderColor: grey.A400,
        }}
      >
        <CardContent
          sx={{
            paddingX: 1,
            paddingY: 1,
            bgcolor: alpha(grey.A200, 1),
            "&:last-child": { pb: 1 },
          }}
        >
          <Stack spacing={1}>
            {props.selectedDatasetValue === "susceptibility" && (
              <>
                <Typography
                  variant="legendTitle"
                  align="center"
                  paddingBottom={0}
                >
                  {t("mapPage.filterDrawer.dataPoints.susceptibility")}
                </Typography>
                <MarkerLegend
                  color="#d32f2f"
                  label={t("mapPage.legend.susceptibility.confirmed")}
                />
                <MarkerLegend
                  color="#ffd54f"
                  label={t("mapPage.legend.susceptibility.possible")}
                />
                <MarkerLegend
                  color="#2e7d32"
                  label={t("mapPage.legend.susceptibility.susceptibility")}
                />
              </>
            )}
            {props.selectedDatasetValue === "intensity" && (
              <>
                <Typography
                  variant="legendTitle"
                  align="center"
                  paddingBottom={0}
                >
                  {t("mapPage.filterDrawer.dataPoints.intensity")}
                </Typography>
                <MarkerLegend
                  color="#691616"
                  label={t("mapPage.legend.intensity.highIntensity")}
                />
                <MarkerLegend
                  color="#AF5F01"
                  label={t("mapPage.legend.intensity.moderateIntensity")}
                />
                <MarkerLegend
                  color="#C69B02"
                  label={t("mapPage.legend.intensity.lowIntensity")}
                />
              </>
            )}
            {props.selectedDatasetValue === "mechanisms" && (
              <>
                <Typography
                  variant="legendTitle"
                  align="center"
                  paddingBottom={0}
                >
                  {t("mapPage.filterDrawer.dataPoints.mechanisms")}
                </Typography>
                <MarkerLegend
                  color="#4527A0"
                  label={t("mapPage.legend.mechanisms.detected")}
                />
                <MarkerLegend
                  color="#64B5F6"
                  label={t("mapPage.legend.mechanisms.notDetected")}
                />
              </>
            )}
          </Stack>
          {props.selectedLayer === "pyrethroid_susceptibility" && (
            <DataRangeLegend
              colorRange={["#fdbdb9", "#e90707"]}
              dataRange={["0", "0.798"]}
              label="Probability of pyrethroid susceptibility"
            />
          )}
          {props.selectedLayer === "anopheles_gambiae" && (
            <DataRangeLegend
              colorRange={["#f2dede", "#c90303"]}
              dataRange={["0.00000912", "0.99999434"]}
              label="Probability of occurence: Anopheles gambiae (2022)"
            />
          )}
          {props.selectedLayer === "anopheles_funestus" && (
            <DataRangeLegend
              colorRange={["#fcd9d9", "#c80909"]}
              dataRange={["0.00000168", "0.99998343"]}
              label="Probability of occurence: Anopheles funestus (2022)"
            />
          )}
        </CardContent>
      </Card>
    </Box>
  );
}
