import { FeatureCollection, Point } from "geojson";
import { MosquitoGenus } from "./mapUtils";

interface anophelesCSVRow {
    Country: string;
    Locality: string;
    Latitude: string;
    Longitude: string;
    Collection_Year_Start: string;
    Collection_Year_End: string;
    Vector_Species: string;
    IR_Test_Method: string;
    Chemical_Class: string;
    Chemical_Type: string;
    Insecticide_Dosage: string;
    IR_Test_NumExposed: string;
    IR_Test_Mortality: string;
    Resistance_Status: string;
    IR_Mechanism_Name: string;
    mutationFrequency: string;
    IR_Mechanism_Status: string;
  }

  interface aedesCSVRow {
    Country: string;
    Locality: string;
    Latitude: string;
    Longitude: string;
    Collection_Year_Start: string;
    Collection_Year_End: string;
    Vector_Species: string;
    Vector_Developmental_Stage: string;
    IR_Test_Method: string;
    Chemical_Class: string;
    Chemical_Type: string;
    Insecticide_Dosage: string;
    IR_Test_NumExposed: string;
    resistanceRatio: string;
    IR_Test_Mortality: string;
    Resistance_Status: string;
    IR_Mechanism_Name: string;
    mutationFrequency: string;
    IR_Mechanism_Status: string;
  }

export interface ParseResults {
    data: anophelesCSVRow[] | aedesCSVRow[];
    errors: any[];
    meta: any;
  }

export const parseAndValidateCSV = (results: ParseResults, mosquitoGenus: MosquitoGenus) => {
  // Check if the CSV headers match the interface
  const anophelesExpectedHeaders = [
    "Country",
    "Locality",
    "Latitude",
    "Longitude",
    "Collection_Year_Start",
    "Collection_Year_End",
    "Vector_Species",
    "IR_Test_Method",
    "Chemical_Class",
    "Chemical_Type",
    "Insecticide_Dosage",
    "IR_Test_NumExposed",
    "IR_Test_Mortality",
    "Resistance_Status",
    "IR_Mechanism_Name",
    "Mutation_Frequency",
    "IR_Mechanism_Status",
  ];

  const aedesExpectedHeaders = [
    "Country",
    "Locality",
    "Latitude",
    "Longitude",
    "Collection_Year_Start",
    "Collection_Year_End",
    "Vector_Species",
    "Vector_Developmental_Stage",
    "IR_Test_Method",
    "Chemical_Class",
    "Chemical_Type",
    "Insecticide_Dosage",
    "IR_Test_NumExposed",
    "Resistance_Ratio",
    "IR_Test_Mortality",
    "Resistance_Status",
    "IR_Mechanism_Name",
    "Mutation_Frequency",
    "IR_Mechanism_Status",
  ];

  const expectedHeaders = mosquitoGenus === 'anopheles' 
  ? anophelesExpectedHeaders
  : aedesExpectedHeaders;

  const actualHeaders = Object.keys(results.data[0]);
  if (!expectedHeaders.every((header) => actualHeaders.includes(header))) {
    throw new Error(
      "CSV structure does not match the expected structure. Please use the template provided."
    );
  }

  results.data.forEach((row: anophelesCSVRow | aedesCSVRow, index: number) => {
    // Check if any values are empty or null
    for (const [key, value] of Object.entries(row)) {
      if (value === null || value === "") {
        throw new Error(
          `Invalid value for ${key} at row ${
            index + 1
          }: value is null or empty. Use "NA" instead.`
        );
      }
    }
    // Check if the collection year values are valid
    if (!/^\d+$/.test(row.Collection_Year_Start)) {
      throw new Error(
        `Invalid value for Collection_Year_Start at row ${
          index + 1
        }: ${row.Collection_Year_Start}`
      );
    }
    if (!/^\d+$/.test(row.Collection_Year_End)) {
      throw new Error(
        `Invalid value for Collection_Year_End at row ${index + 1}: ${
          row.Collection_Year_End
        }`
      );
    }
    // Check that required columns have valid values
    const validRCodes = mosquitoGenus === 'anopheles' 
    ? ["Confirmed resistance", "Possible resistance", "Susceptibility", "High intensity resistance", "Moderate intensity resistance", "Low intensity resistance"]
    : ["Confirmed resistance", "Possible resistance", "Susceptibility"];
    const validMechanismCodes = ["Detected", "Not detected"];
    if (
      !validRCodes.includes(row.Resistance_Status) &&
      !validMechanismCodes.includes(row.IR_Mechanism_Status)
    ) {
      throw new Error(
        `Invalid values at row ${index + 1}: Resistance_Status is ${
          row.Resistance_Status
        }, IR_Mechanism_Status is ${row.IR_Mechanism_Status}`
      );
    }
  });
};

type CSVRow = anophelesCSVRow | aedesCSVRow;

const createBaseProperties = (row: CSVRow, index: number) => ({
  csvid: index + 1,
  latitude: parseFloat(row.Latitude),
  longitude: parseFloat(row.Longitude),
  resistanceStatus: row.Resistance_Status,
  mechanismDetectionStatus: row.IR_Mechanism_Status,
  collectionStartYear: parseInt(row.Collection_Year_Start),
  collectionEndYear: parseInt(row.Collection_Year_End),
  country: row.Country,
  locality: row.Locality,
  vectorSpecies: row.Vector_Species,
  testMethod: row.IR_Test_Method,
  chemicalClass: row.Chemical_Class,
  chemicalType: row.Chemical_Type,
  insecticideDosage: row.Insecticide_Dosage,
  iRTestNumExposed: row.IR_Test_NumExposed,
  iracMoACode: "NA",
  testMortality: row.IR_Test_Mortality,
  mechanismName: row.IR_Mechanism_Name,
  mutationFrequency: row.mutationFrequency,
  referenceType: "NA",
  referenceName: "NA",
  url: "NA",
});

const createAedesProperties = (row: aedesCSVRow) => {
  const aedesProperties: { vectorDevStage?: string, resistanceRatio?: string, synergistType?: string} = {};
  if ('Vector_developmental_stage' in row) {
    aedesProperties.vectorDevStage = row.Vector_Developmental_Stage;
    aedesProperties.resistanceRatio = row.resistanceRatio;
    aedesProperties.synergistType = "NA";

  }
  return aedesProperties;
};

export const createGeoJsonData = (
  results: ParseResults,
  mosquitoGenus: MosquitoGenus
): FeatureCollection<Point> => {
  return {
    type: "FeatureCollection" as "FeatureCollection",
    features: results.data.map((row: CSVRow, index: number) => {
      const baseProperties = createBaseProperties(row, index);
      let specificProperties = {};

      if (mosquitoGenus === 'aedes' && 'Vector_developmental_stage' in row) {
        specificProperties = createAedesProperties(row as aedesCSVRow);
      }

      return {
        type: "Feature" as "Feature",
        properties: {
          ...baseProperties,
          ...specificProperties,
        },
        geometry: {
          type: "Point" as "Point",
          coordinates: [parseFloat(row.Longitude), parseFloat(row.Latitude)],
        },
      };
    }),
  };
};
