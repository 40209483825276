import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import DrawerTitle from "./DrawerTitle";
import ControlledDataPointsRadioButtonsGroup from "./filters/ControlledDataPointsRadioButtonsGroup";
import { Stack } from "@mui/material";
import { SelectedDatasetValue } from "./filters/ControlledDataPointsRadioButtonsGroup";
import StatusCheckboxGroup from "./filters/StatusCheckboxGroup";
import CollectionYearRange from "./filters/CollectionYearRange";
import CountryFilter from "./filters/CountryFilter";
import VectorSpeciesFilter from "./filters/VectorSpeciesFilter";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Button from "@mui/material/Button";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import { useTranslation } from "react-i18next";
import IRTestMethodFilter from "./filters/IRTestMethodFilter";
import { useState } from "react";
import InsecticideClassFilter from "./filters/InsecticideClassFilter";
import InsecticideTypeFilter from "./filters/InsecticideTypeFilter";
import IRACMoAFilter from "./filters/IRACMoAFilter";
import IRMechanismNameFilter from "./filters/IRMechanismNameFIlter";
import { MosquitoGenus } from "../../utils/mapUtils";
import VectorDevStageFilter from "./filters/VectorDevStageFilter";

interface FilterDrawerProps {
  mosquitoGenus: MosquitoGenus;
  selectedDatasetValue: SelectedDatasetValue;
  open: boolean;
  toggleDrawer: (newOpen: boolean) => () => void;
  onRadioButtonChange: (value: SelectedDatasetValue) => void;
  onFilterChange: () => void;
  yearRange: number[];
  onYearRangeChange: (newYearRange: number[]) => void;
  countryOptions: string[];
  countries: string[];
  onCountriesChange: (newCountry: string[]) => void;
  vectorSpeciesOptions: string[];
  vectorSpecies: string[];
  onVectorSpeciesChange: (newVectorSpecies: string[]) => void;
  vectorDevStageOptions: string[];
  vectorDevStage: string[];
  onVectorDevStageChange: (newVectorSpecies: string[]) => void;
  testMethods: string[];
  onTestMethodsChange: (newTestMethod: string[]) => void;
  insecticideClassOptions: string[];
  insecticideClass: string[];
  onInsecticideClassChange: (newInsecticideClass: string[]) => void;
  insecticideTypeOptions: Record<string, string[]>;
  insecticideType: string[];
  onInsecticideTypeChange: (newInsecticideType: string[]) => void;
  IRACMoAOptions: string[];
  IRACMoA: string[];
  onIRACMoAChange: (newIRACMoA: string[]) => void;
  IRMechanismNameOptions: string[];
  IRMechanismName: string[];
  onIRMechanismNameChange: (newIRMechanismName: string[]) => void;
}

const FilterDrawer: React.FC<FilterDrawerProps> = (
  props: FilterDrawerProps
) => {
  // Use open, and toggleDrawer as necessary in your Drawer code
  const { t } = useTranslation();

  const [expanded, setExpanded] = useState(false);

  const handleAccordionChange = (
    event: React.SyntheticEvent,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded);
  };

  const DrawerContent = (
    <Box sx={{ width: 396 }} role="presentation">
      <DrawerTitle toggleDrawer={props.toggleDrawer} />
      <Stack alignItems={"left"} spacing={3} paddingX={4} paddingY={4}>
        <ControlledDataPointsRadioButtonsGroup
          value={props.selectedDatasetValue}
          onChange={props.onRadioButtonChange}
          onFilterChange={props.onFilterChange}
          mosquitoGenus={props.mosquitoGenus}
        />
        <StatusCheckboxGroup
          selectedDatasetValue={props.selectedDatasetValue}
          onFilterChange={props.onFilterChange}
        />
        <CollectionYearRange
          yearRange={props.yearRange}
          onYearRangeChange={props.onYearRangeChange}
          onFilterChange={props.onFilterChange}
        />
        <CountryFilter
          countryOptions={props.countryOptions}
          countries={props.countries}
          onCountriesChange={props.onCountriesChange}
          onFilterChange={props.onFilterChange}
        />
        <InsecticideClassFilter
          insecticideClassOptions={props.insecticideClassOptions}
          insecticideClass={props.insecticideClass}
          onInsecticideClassChange={props.onInsecticideClassChange}
          onFilterChange={props.onFilterChange}
        />
        <VectorSpeciesFilter
          vectorSpeciesOptions={props.vectorSpeciesOptions}
          vectorSpecies={props.vectorSpecies}
          onVectorSpeciesChange={props.onVectorSpeciesChange}
          onFilterChange={props.onFilterChange}
        />
        {props.mosquitoGenus === "aedes" && (
          <VectorDevStageFilter
            vectorDevStageOptions={props.vectorDevStageOptions}
            vectorDevStage={props.vectorDevStage}
            onVectorDevStageChange={props.onVectorDevStageChange}
            onFilterChange={props.onFilterChange}
          />
        )}
        <Accordion
          expanded={expanded}
          onChange={handleAccordionChange}
          elevation={0}
          sx={{
            border: "none", // Removes borders
            paddingX: 0, // Removes padding
            "&::before": {
              // Targets the before pseudo-element
              content: "none", // Removes the content
            },
          }}
        >
          <AccordionSummary
            sx={{
              paddingX: 0, // Removes padding
            }}
          >
            <Button
              variant="text"
              size="large"
              endIcon={
                expanded ? (
                  <ExpandLessOutlinedIcon />
                ) : (
                  <ExpandMoreOutlinedIcon />
                )
              }
              fullWidth
            >
              {expanded
                ? t("mapPage.filterDrawer.accordion.expanded")
                : t("mapPage.filterDrawer.accordion.collapsed")}
            </Button>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              paddingX: 0, // Removes padding
            }}
          >
            <Stack alignItems={"left"} spacing={3}>
              <IRTestMethodFilter
                selectedDatasetValue={props.selectedDatasetValue}
                testMethods={props.testMethods}
                onTestMethodsChange={props.onTestMethodsChange}
                onFilterChange={props.onFilterChange}
              />
              <InsecticideTypeFilter
                insecticideClass={props.insecticideClass}
                insecticideTypeOptions={props.insecticideTypeOptions}
                insecticideType={props.insecticideType}
                onInsecticideTypeChange={props.onInsecticideTypeChange}
                onFilterChange={props.onFilterChange}
              />
              <IRACMoAFilter
                IRACMoAOptions={props.IRACMoAOptions}
                IRACMoA={props.IRACMoA}
                onIRACMoAChange={props.onIRACMoAChange}
                onFilterChange={props.onFilterChange}
              />
              <IRMechanismNameFilter
                IRMechanismNameOptions={props.IRMechanismNameOptions}
                IRMechanismName={props.IRMechanismName}
                onIRMechanismNameChange={props.onIRMechanismNameChange}
                onFilterChange={props.onFilterChange}
              />
            </Stack>
          </AccordionDetails>
        </Accordion>
      </Stack>
    </Box>
  );

  return (
    <Drawer
      open={props.open}
      onClose={props.toggleDrawer(false)}
      PaperProps={{
        sx: {
          height: "calc(100% - 132px)",
          top: 132,
        },
      }}
    >
      {DrawerContent}
    </Drawer>
  );
};

export default FilterDrawer;
