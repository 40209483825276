import * as React from "react";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";

interface InsecticideClassFilterProps {
  insecticideClassOptions: string[];
  insecticideClass: string[];
  onInsecticideClassChange: (newInsecticideClass: string[]) => void;
  onFilterChange?: () => void;
}

export default function InsecticideClassFilter({
  insecticideClassOptions,
  insecticideClass,
  onInsecticideClassChange,
  onFilterChange,
}: InsecticideClassFilterProps) {
  const { t } = useTranslation();

  const handleChange = (newValue: string | string[]) => {
    onInsecticideClassChange(newValue as string[]);
  };

  return (
    <FormControl fullWidth>
      <FormLabel id="insecticide-class-group" sx={{ paddingBottom: 2 }}>
        {t("mapPage.filterDrawer.insecticideClass.title")}
      </FormLabel>
      <Box>
        <Autocomplete
          fullWidth={true}
          multiple
          id="insecticide-class-filter"
          value={insecticideClass}
          onChange={(event, newValue) => {
            handleChange(newValue);
            if (onFilterChange) {
              onFilterChange();
            }
          }}
          options={insecticideClassOptions.filter(option => option !== 'NA' && option !== 'Na').sort((a, b) => a.localeCompare(b))}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip label={option} {...getTagProps({ index })} />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("mapPage.filterDrawer.insecticideClass.title")}
            />
          )}
        />
      </Box>
    </FormControl>
  );
}
