import mapboxgl from "mapbox-gl";
import ReactDOM from "react-dom/client";
import PopupContent from "../components/map-pages/PopupContent";
import { MosquitoGenus } from "./mapUtils";

const setFeatureState = (
  map: mapboxgl.Map,
  sourceId: string,
  featureId: string | number | undefined | null,
  state: any
) => {
  if (featureId !== null) {
    map.setFeatureState({ source: sourceId, id: featureId }, state);
  }
};

export const handleClick = (
  map: mapboxgl.Map,
  sourceId: string,
  mosquitoGenus: MosquitoGenus,
  e: mapboxgl.MapMouseEvent & {
    features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
  } & mapboxgl.EventData
) => {
  // When a click event occurs on a feature in a visible layer, open a popup at the
  // location of the feature, showing its properties.
  const features = map
    .queryRenderedFeatures(e.point)
    .filter((feature) => feature.source === sourceId);
  const feature = features?.[0];
  if (feature?.geometry.type === "Point") {
    // Copy coordinates array.
    const coordinates: [number, number] = [
      feature.properties?.longitude,
      feature.properties?.latitude,
    ];

    // Ensure that if the map is zoomed out such that multiple
    // copies of the feature are visible, the popup appears
    // over the copy being pointed to.
    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
      coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
    }

    // Create a new div element
    const div = document.createElement("div");

    // Use ReactDOM.createRoot to render the PopupContent component to the div
    ReactDOM.createRoot(div).render(<PopupContent features={features} mosquitoGenus={mosquitoGenus}/>);

    new mapboxgl.Popup().setLngLat(coordinates).setDOMContent(div).addTo(map);
  }
};

export const handleMouseEnter = (
  map: mapboxgl.Map,
  sourceId: string,
  e: mapboxgl.MapMouseEvent & {
    features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
  } & mapboxgl.EventData,
  hoveredFeatureId: string | number | undefined | null
) => {
  // Change the cursor to a pointer and show hover state when the mouse is over the visible layer features.
  map.getCanvas().style.cursor = "pointer";
  const feature = e.features?.[0];
  if (feature) {
    hoveredFeatureId = feature.id;
    setFeatureState(map, sourceId, hoveredFeatureId, { hover: true });
  }
  return hoveredFeatureId;
};

export const handleMouseLeave = (
  map: mapboxgl.Map,
  sourceId: string,
  hoveredFeatureId: string | number | undefined | null
) => {
  // Change cursor and hover state back when it leaves.
  map.getCanvas().style.cursor = "";
  setFeatureState(map, sourceId, hoveredFeatureId, { hover: false });
  hoveredFeatureId = null;

  return hoveredFeatureId;
};
