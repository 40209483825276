import Stack from "@mui/material/Stack";
import HeroSection from "../components/home-page/HeroSection";
import Headline from "../components/home-page/Headline";
import FeaturesGrid from "../components/home-page/FeaturesGrid";
import Divider from "@mui/material/Divider";
import ContactForm from "../components/home-page/ContactForm";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

const HomePage = () => {
  return (
    <Stack>
      <Stack
        spacing={4}
        paddingY={4}
        alignItems={"center"}
        justifyContent={"flex-start"}
        alignSelf={"stretch"}
        sx={{ paddingX: { xs: 0, md: 8 } }}
      >
        <HeroSection />
        <Stack paddingY={10} paddingX={4} spacing={6} alignItems={"center"}>
          <Headline />
          <FeaturesGrid />
          <Typography variant="body1" textAlign={"center"}>
           Data is updated monthly from the latest peer-reviewed publications and national malaria control country specific reports. 
           Upload & Compare push-button allows users to upload their own data sets for visualization and comparison with the existing published data. 
          </Typography>
        </Stack>
        <Divider flexItem />
        <Stack direction={"column"} spacing={6} paddingY={10} paddingX={8}>
          <Typography variant="h4" textAlign={"center"}>
            {" "}
            IR Mapper overview{" "}
          </Typography>
          <Typography variant="body1">
          Launched in 2012, IR Mapper is a critical tool that collates, aggregates and visualizes data 
          from standardized WHO or CDC tests on mosquito susceptibility to insecticides. 
          Originally focused on Anopheles mosquitoes, the primary malaria vector, 
          it now also includes resistance data for Aedes aegypti and albopictus, which transmit diseases like dengue, chikungunya, and Zika. 
          Users can explore and upload resistance data, comparing it with published results.
          In 2020, IR Mapper added features to visualize modeled resistance layers and intensity data points, 
          offering a clearer view of resistance spread and severity to support informed vector control decisions.
          </Typography>
        </Stack>
        <Divider flexItem />
        <Stack direction={"column"} spacing={6} paddingY={10} paddingX={8}>
          <Typography variant="h4" textAlign={"center"}>
            {" "}
            Understanding Insecticide Resistance Levels{" "}
          </Typography>
          <Typography variant="body1">
            <strong>Standardized insecticide susceptibility tests</strong> are crucial for assessing the ability of insect populations to resist insecticides. 
            <strong>The World Health Organization (WHO)</strong> categorizes resistance based on mortality rates and resistance ratios (RR). 
            <strong>Confirmed resistance</strong> occurs when mortality drops below 90% or RR exceeds 10. 
            <strong>Possible resistance</strong> is indicated by 90-97% mortality or RR between 5 and 9.
            <strong>Susceptibility</strong> is defined as 98-100% mortality or RR less than 5.
          </Typography>
        </Stack>
        <Divider flexItem />
        <Stack direction={"column"} spacing={6} paddingY={10} paddingX={8}>
          <Typography variant="h4" textAlign={"center"}>
            {" "}
            Data Sources{" "}
          </Typography>
          <Typography variant="body1">
            Data is extracted monthly from peer-reviewed scientific publications and reports, 
            including the President's Malaria Initiative (PMI) country Insecticide Susceptibility reports (for Anopheles map) and VectorBase. 
            The Aedes map incorporates data provided by Professor Hilary Ranson in 2016.
          </Typography>
          <Typography variant="body1">
            A new menu on the Anopheles map allows users to view modelled resistance layers developed by Professor Catherine Moyes' Geospatial Modelling of Insect Vectors (GMIV) group. 
            These layers fill into data gaps in insecticide resistance surveillance across sub-Saharan Africa and are generated using a published geostatistical ensemble model. 
            They estimate the mean mortality rates of Anopheles gambiae s.l. to insecticides like alphacypermethrin, lambda-cyhalothrin, deltamethrin, permethrin, and DDT from 2005 to 2022. 
            Additionally, probability layers created through collaboration between GMIV, LSTM, CHAI, and IR Mapper 
            indicate the likelihood that pyrethroid resistance in Anopheles gambiae s.l. (at the district level) 
            exceeds WHO thresholds for susceptibility, confirmed resistance, 
            or the 10-80% mortality range for deploying piperonyl butoxide-treated nets, which mitigate metabolic resistance to pyrethroids.
          </Typography>
          <Typography variant="body1">
            Though Anopheles funestus s.l. is another key malaria vector in Africa, 
            there is insufficient data to model pyrethroid resistance for this species. 
            In areas where An. funestus s.l. is present, users should consider An. gambiae s.l. resistance data 
            along with local data on An. funestus s.l.. 
            The Anopheles map also includes a feature that allows users to download analysis-ready geospatial datasets on insecticide resistance in malaria vectors, 
            which can be used to track trends in resistance over time and across regions. For access to the most up-to-date dataset, 
            please contact <a href="mailto:info@irmapper.com">info@irmapper.com</a>.
          </Typography>
        </Stack>
        <Divider flexItem />
        <Stack direction={"column"} spacing={6} paddingY={10} paddingX={8}>
          <Typography variant="h4" textAlign={"center"}>
            {" "}
            Latest Updates{" "}
          </Typography>
          <Typography variant="body1">
            The latest IR Mapper update introduces MapBox as our new mapping platform, offering enhanced flexibility and faster data loading times for all available up-to-date insecticide resistance data.
          </Typography>
          <Typography variant="body1">
            Here is a longer description of the updates that have been made:
          </Typography>
          <List sx={{ listStyleType: "disc", pl: 2 }}>
              <ListItem sx={{ display: "list-item" }}>
                <ListItemText primary="Moyes CL, Athinya DK, Seethaler T, et al. Evaluating insecticide resistance across African districts to aid malaria control decisions. Proc Natl Acad Sci. August 2020:202006781." />
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                <ListItemText primary="Hancock, P. A., Hendriks, C., Tangena, J. A., Gibson, H., Hemingway, J., Coleman, M., Gething, P. W., Cameron, E., Bhatt, S., & Moyes, C. L. (2020). Mapping trends in insecticide resistance phenotypes in African malaria vectors. PLoS Biology, 18(6), e3000633." />
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                <ListItemText primary="Weiss, D. J., Lucas, T. C., Nguyen, M., Nandi, A. K., Bisanzio, D., Battle, K. E., ... & Gibson, H. S. (2019). Mapping the global prevalence, incidence, and mortality of Plasmodium falciparum, 2000-17: a spatial and temporal modelling study. The Lancet, 394(10195), 322-331." />
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                <ListItemText primary="Battle, K. E., Lucas, T. C., Nguyen, M., Howes, R. E., Nandi, A. K., Twohig, K. A., ... & Gibson, H. S. (2019). Mapping the global endemicity and clinical burden of Plasmodium vivax, 2000-17: a spatial and temporal modelling study. The Lancet, 394(10195), 332-343." />
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                <ListItemText primary="Messina, J. P., Kraemer, M. U., Brady, O. J., Pigott, D. M., Shearer, F. M., Weiss, D. J., ... & Brownstein, J. S. (2016). Mapping global environmental suitability for Zika virus. Elife, 5, e15272." />
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                <ListItemText primary="Bhatt, S., Gething, P. W., Brady, O. J., Messina, J. P., Farlow, A. W., Moyes, C. L., ... & Myers, M. F. (2013). The global distribution and burden of dengue. Nature, 496(7446), 504-507." />
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                <ListItemText primary="Nsoesie, E. O., Kraemer, M. U., Golding, N., Pigott, D. M., Brady, O. J., Moyes, C. L., ... & Hay, S. I. (2016). Global distribution and environmental suitability for chikungunya virus, 1952 to 2015. Eurosurveillance, 21(20), 30234." />
              </ListItem>
            </List>
        </Stack>
        <Divider flexItem />
        <Stack direction={"column"} spacing={6} paddingY={10} paddingX={8}>
          <Typography variant="h4" textAlign={"center"}>
            {" "}
            IR Mapper process{" "}
          </Typography>
          <Typography variant="body1">
            IR Mapper is a collaborative effort between Vestergaard and KEMRI-CGHR, focused on improving insecticide resistance surveillance. 
            Vestergaard collates and aggregates data on a monthly basis, while KEMRI-CGHR verifies its accuracy. 
            The validated data is then uploaded to a cloud platform, where it is displayed on an interactive map. 
            This system helps stakeholders monitor malaria trends, identify resistance hotspots, and guide targeted interventions.
          </Typography>
          <Box
            height={"300px"}
            alignSelf={"stretch"}
            sx={{
              position: "relative",
              backgroundImage: `url(/ir_mapper_process_new.png)`,
              backgroundSize: "contain",
              backgroundPosition: "50%",
              backgroundRepeat: "no-repeat",
            }}
          />
        </Stack>
      </Stack>
      <ContactForm />
    </Stack>
  );
};

export default HomePage;
