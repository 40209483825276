import { AedesRawSamplingPoint } from "../AedesData";
import { AnophelesRawSamplingPoint } from "../AnophelesData";
import {
  aedesCSVRow,
  anophelesCSVRow,
  IRMapperCSVRow,
} from "./iRMapperDataCSVImport";
import { MosquitoGenus } from "./mapUtils";

// Function to transform IRMapperCSVRow to RawSamplingPoint
const transformToRawSamplingPoint = (
  mosquitoGenus: MosquitoGenus,
  row: IRMapperCSVRow
): AnophelesRawSamplingPoint | AedesRawSamplingPoint => {
  if (mosquitoGenus === "anopheles") {
    const anophelesRow = row as anophelesCSVRow;
    return {
      id: String(anophelesRow.id),
      country: String(anophelesRow.country),
      locality: String(anophelesRow.locality),
      latitude: String(anophelesRow.latitude),
      longitude: String(anophelesRow.longitude),
      collection_Year_Start: Number(anophelesRow.collection_Year_Start),
      collection_Year_End: Number(anophelesRow.collection_Year_End),
      vector_Species: String(anophelesRow.vector_Species),
      molecular_Forms: String(anophelesRow.molecular_Forms),
      iR_Test_Method: String(anophelesRow.iR_Test_Method),
      chemical_Class: String(anophelesRow.chemical_Class),
      chemical_Type: String(anophelesRow.chemical_Type),
      insecticide_Dosage: String(anophelesRow.insecticide_Dosage),
      iraC_MoA: String(anophelesRow.iraC_MoA),
      iraC_MoA_Code: String(anophelesRow.iraC_MoA_Code),
      iR_Test_NumExposed: String(anophelesRow.iR_Test_NumExposed),
      iR_Test_Mortality: String(anophelesRow.iR_Test_Mortality),
      resistance_Status: String(anophelesRow.resistance_Status),
      iR_Mechanism_Name: String(anophelesRow.iR_Mechanism_Name),
      resistance_Mechanism_Frequency: String(
        anophelesRow.resistance_Mechanism_Frequency
      ),
      iR_Mechanism_Status: String(anophelesRow.iR_Mechanism_Status),
      reference_Type: String(anophelesRow.reference_Type),
      reference_Name: String(anophelesRow.reference_Name),
      url: String(anophelesRow.url),
    };
  } else if (mosquitoGenus === "aedes") {
    const aedesRow = row as aedesCSVRow;
    return {
      id: String(aedesRow.id),
      country: String(aedesRow.country),
      locality: String(aedesRow.locality),
      latitude: String(aedesRow.latitude),
      longitude: String(aedesRow.longitude),
      collection_Year_Start: Number(aedesRow.collection_Year_Start),
      collection_Year_End: Number(aedesRow.collection_Year_End),
      vector_Species: String(aedesRow.vector_Species),
      vector_Developmental_Stage: String(aedesRow.vector_Developmental_Stage),
      iR_Test_Method: String(aedesRow.iR_Test_Method),
      chemical_Class: String(aedesRow.chemical_Class),
      chemical_Type: String(aedesRow.chemical_Type),
      insecticide_Dosage: String(aedesRow.insecticide_Dosage),
      iraC_MoA: String(aedesRow.iraC_MoA),
      iraC_MoA_Code: String(aedesRow.iraC_MoA_Code),
      ir_Test_NumExposed: String(aedesRow.iR_Test_NumExposed),
      resistance_Ratio: String(aedesRow.resistance_Ratio),
      iR_Test_Mortality: String(aedesRow.iR_Test_Mortality),
      resistance_Status: String(aedesRow.resistance_Status),
      synergist_Type: String(aedesRow.synergist_Type),
      synergist_Dosage: String(aedesRow.synergist_Dosage),
      synergist_Test_Mortality: String(aedesRow.synergist_Test_Mortality),
      iR_Mechanism_Name: String(aedesRow.iR_Mechanism_Name),
      mutation_Frequency: String(aedesRow.mutation_frequency),
      iR_Mechanism_Status: String(aedesRow.iR_Mechanism_Status),
      reference_Type: String(aedesRow.reference_Type),
      reference_Name: String(aedesRow.reference_Name),
      url: String(aedesRow.url),
    };
  } else {
    throw new Error("Invalid mosquito genus");
  }
};

const removeId = (row: any) => {
  const { id, ...rest } = row;
  return rest;
};

export const updateDatabase = async (
  mosquitoGenus: MosquitoGenus,
  addedRows: IRMapperCSVRow[],
  removedRows: IRMapperCSVRow[],
  updatedRows: IRMapperCSVRow[]
): Promise<void> => {
  const API_URL =
    process.env.NODE_ENV === "production" ? process.env.REACT_APP_API_URL : "";

  const transformedAddedRows = addedRows.map((row) =>
    removeId(transformToRawSamplingPoint(mosquitoGenus, row))
  );
  const transformedRemovedRows = removedRows.map((row) =>
    transformToRawSamplingPoint(mosquitoGenus, row)
  );
  const transformedUpdatedRows = updatedRows.map((row) =>
    transformToRawSamplingPoint(mosquitoGenus, row)
  );

  const endpoint = mosquitoGenus === "anopheles" ? "Anopheles" : "Aedes";

  // Add new rows
  for (const row of transformedAddedRows) {
    const response = await fetch(`${API_URL}/api/${endpoint}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(row),
    });
    if (!response.ok) {
      throw new Error(`Failed to add row: ${response.statusText}`);
    }
  }

  // Remove rows
  for (const row of transformedRemovedRows) {
    const response = await fetch(`${API_URL}/api/${endpoint}/${row.id}`, {
      method: "DELETE",
    });
    if (!response.ok) {
      throw new Error(`Failed to delete row: ${response.statusText}`);
    }
  }

  // Update rows
  for (const row of transformedUpdatedRows) {
    const response = await fetch(`${API_URL}/api/${endpoint}/${row.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(row),
    });
    if (!response.ok) {
      throw new Error(`Failed to update row: ${response.statusText}`);
    }
  }
};
