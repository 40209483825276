import Papa from "papaparse";
import { MosquitoGenus } from "./mapUtils";

export interface anophelesCSVRow {
  id: string;
  country: string;
  locality: string;
  latitude: string;
  longitude: string;
  collection_Year_Start: number;
  collection_Year_End: number;
  vector_Species: string;
  molecular_Forms: string;
  iR_Test_Method: string;
  chemical_Class: string;
  chemical_Type: string;
  insecticide_Dosage: string;
  iraC_MoA: string;
  iraC_MoA_Code: string;
  iR_Test_NumExposed: string;
  iR_Test_Mortality: string;
  resistance_Status: string;
  iR_Mechanism_Name: string;
  resistance_Mechanism_Frequency: string;
  iR_Mechanism_Status: string;
  reference_Type: string;
  reference_Name: string;
  url: string;
}

export interface aedesCSVRow {
  id: string;
  country: string;
  locality: string;
  latitude: string;
  longitude: string;
  collection_Year_Start: number;
  collection_Year_End: number;
  vector_Species: string;
  vector_Developmental_Stage: string;
  iR_Test_Method: string;
  chemical_Class: string;
  chemical_Type: string;
  insecticide_Dosage: string;
  iraC_MoA: string;
  iraC_MoA_Code: string;
  iR_Test_NumExposed: string;
  resistance_Ratio: string;
  iR_Test_Mortality: string;
  resistance_Status: string;
  synergist_Type: string;
  synergist_Dosage: string;
  synergist_Test_Mortality: string;
  iR_Mechanism_Name: string;
  mutation_frequency: string;
  iR_Mechanism_Status: string;
  reference_Type: string;
  reference_Name: string;
  url: string;
}

export type IRMapperCSVRow = anophelesCSVRow | aedesCSVRow;

export interface ParseResults {
  data: anophelesCSVRow[] | aedesCSVRow[];
  errors: any[];
  meta: any;
}

export interface ComparisonResults<T> {
  addedRows: T[];
  updatedRows: T[];
  removedRows: T[];
}

interface IndexedRowsResult {
  indexedRows: Map<string, anophelesCSVRow | aedesCSVRow>;
  nullIDRows: (anophelesCSVRow | aedesCSVRow)[];
}

// Function to check for null or empty values
function checkForEmptyOrNullValues(row: any, columnsToCheck: string[]) {
  columnsToCheck.forEach((column) => {
    if (row[column] === null || row[column] === "") {
      throw new Error(
        `Invalid value for ${column} at row with id: ${row.id} : value is null or empty.`
      );
    }
  });
}

function isCollectionYear(year: number): boolean {
  return (
    typeof year === "number" &&
    !isNaN(year) &&
    year > 0 &&
    year < 32767 &&
    Number.isInteger(year)
  );
}

function isFloat(value: string): boolean {
  const float = parseFloat(value);
  return !isNaN(float) && isFinite(float);
}

function isCSVRow(row: any): row is anophelesCSVRow | aedesCSVRow {
  return "id" in row;
}

function indexRowsByIDs(csvData: string): IndexedRowsResult {
  // Parse the CSV data
  const parseResult = Papa.parse(csvData, {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
  });

  // Initialize a map for indexed rows and an array for rows with null or missing IDs
  const indexedRows = new Map<string, anophelesCSVRow | aedesCSVRow>();
  const nullIDRows: (anophelesCSVRow | aedesCSVRow)[] = [];

  // Iterate over each row
  parseResult.data.forEach((row: any) => {
    if (isCSVRow(row)) {
      const id = row.id;
      if (id) {
        // If the row has a valid ID, index it by the ID
        indexedRows.set(id, row);
      } else {
        // If the row has a null or missing ID, add it to the nullIDRows array
        nullIDRows.push(row);
      }
    }
  });

  // Return the indexed rows and the rows with null or missing IDs
  return { indexedRows, nullIDRows };
}

function isRowUpdated(
  originalRow: anophelesCSVRow | aedesCSVRow,
  updatedRow: anophelesCSVRow | aedesCSVRow
): boolean {
  for (const [field, updatedValue] of Object.entries(updatedRow)) {
    if (updatedValue !== (originalRow as any)[field]) {
      return true;
    }
  }
  return false;
}

export const parseAndValidateIRMapperCSV = (
  results: ParseResults,
  mosquitoGenus: MosquitoGenus
) => {

  // Check if the CSV headers match the interface
  const anophelesExpectedHeaders = [
    "id",
    "country",
    "locality",
    "latitude",
    "longitude",
    "collection_Year_Start",
    "collection_Year_End",
    "vector_Species",
    "molecular_Forms",
    "iR_Test_Method",
    "chemical_Class",
    "chemical_Type",
    "insecticide_Dosage",
    "iraC_MoA",
    "iraC_MoA_Code",
    "iR_Test_NumExposed",
    "iR_Test_Mortality",
    "resistance_Status",
    "iR_Mechanism_Name",
    "resistance_Mechanism_Frequency",
    "iR_Mechanism_Status",
    "reference_Type",
    "reference_Name",
    "url",
  ];

  const aedesExpectedHeaders = [
    "id",
    "country",
    "locality",
    "latitude",
    "longitude",
    "collection_Year_Start",
    "collection_Year_End",
    "vector_Species",
    "vector_Developmental_Stage",
    "iR_Test_Method",
    "chemical_Class",
    "chemical_Type",
    "insecticide_Dosage",
    "iraC_MoA",
    "iraC_MoA_Code",
    "iR_Test_NumExposed",
    "resistance_Ratio",
    "iR_Test_mortality",
    "resistance_Status",
    "synergist_Type",
    "synergist_Dosage",
    "synergist_Test_Mortality",
    "iR_Mechanism_Name",
    "mutation_frequency",
    "iR_Mechanism_Status",
    "reference_Type",
    "reference_Name",
    "url",
  ];

  const expectedHeaders =
    mosquitoGenus === "anopheles"
      ? anophelesExpectedHeaders
      : aedesExpectedHeaders;

  const actualHeaders = Object.keys(results.data[0]);
  if (!expectedHeaders.every((header) => actualHeaders.includes(header))) {
    throw new Error(
      "CSV structure does not match the expected structure. Please update the CSV provided, and do not add, rename or remove columns."
    );
  }

  results.data.forEach((row: anophelesCSVRow | aedesCSVRow, index: number) => {
    // Check if any values are empty or null
    const anophelesNotNullColumns = [
      "country",
      "locality",
      "latitude",
      "longitude",
      "collection_Year_Start",
      "collection_Year_End",
      "vector_Species",
      "iR_Test_Method",
      "resistance_Status",
      "iR_Mechanism_Status",
      "reference_Type",
      "reference_Name",
    ];
    const aedesNotNullColumns = [
      "country",
      "locality",
      "latitude",
      "longitude",
      "collection_Year_Start",
      "collection_Year_End",
      "vector_Species",
      "vector_Developmental_Stage",
      "iR_Test_Method",
      "resistance_Status",
      "iR_Mechanism_Status",
      "reference_Type",
      "reference_Name",
    ];

    const columnsToCheck =
      mosquitoGenus === "anopheles"
        ? anophelesNotNullColumns
        : aedesNotNullColumns;
    checkForEmptyOrNullValues(row, columnsToCheck);

    // Check if the collection year values are valid
    if (!isCollectionYear(Number(row.collection_Year_Start))) {
      throw new Error(
        `Invalid collection_Year_Start value at row ${index + 1}: ${
          row.collection_Year_Start
        }. Must be a positive integer number.`
      );
    }

    if (!isCollectionYear(Number(row.collection_Year_End))) {
      throw new Error(
        `Invalid collection_Year_End value at row ${index + 1}: ${
          row.collection_Year_End
        }. Must be a positive integer number.`
      );
    }

    // Validate latitude and longitude as floats
    if (!isFloat(row.latitude)) {
      throw new Error(
        `Invalid latitude value at row ${index + 1}: ${
          row.latitude
        }. Must be a float.`
      );
    }
    if (!isFloat(row.longitude)) {
      throw new Error(
        `Invalid longitude value at row ${index + 1}: ${
          row.longitude
        }. Must be a float.`
      );
    }

    // Check that required columns have valid values
    const validRCodes =
      mosquitoGenus === "anopheles"
        ? [
            "Confirmed resistance",
            "Possible resistance",
            "Susceptibility",
            "High intensity resistance",
            "Moderate intensity resistance",
            "Low intensity resistance",
          ]
        : ["Confirmed resistance", "Possible resistance", "Susceptibility"];
    const validMechanismCodes = ["Detected", "Not detected"];
    if (
      !validRCodes.includes(row.resistance_Status) &&
      !validMechanismCodes.includes(row.iR_Mechanism_Status)
    ) {
      throw new Error(
        `Invalid values at row ${index + 1}: Resistance_Status is ${
          row.resistance_Status
        }, IR_Mechanism_Status is ${row.iR_Mechanism_Status}`
      );
    }
  });
};

export function compareCSVs<T extends IRMapperCSVRow>(
  originalCSV: string,
  updatedCSV: string
): ComparisonResults<T> {
  try {
    // Parse CSVs and index rows by ID, separating null ID rows
    const { indexedRows: indexedOriginalRows } = indexRowsByIDs(originalCSV);
    const { indexedRows: indexedUpdatedRows, nullIDRows: nullIDUpdatedRows } =
      indexRowsByIDs(updatedCSV);

    const addedRows: T[] = [];
    const updatedRows: T[] = [];
    const removedRows: T[] = [];

    indexedUpdatedRows.forEach((updatedRow, id) => {
      if (indexedOriginalRows.has(id)) {
        const originalRow = indexedOriginalRows.get(id);

        if (
          originalRow !== undefined &&
          isRowUpdated(originalRow, updatedRow)
        ) {
          updatedRows.push(updatedRow as T);
        }
      } else {
        addedRows.push(updatedRow as T);
      }
    });

    indexedOriginalRows.forEach((value, key) => {
      if (!indexedUpdatedRows.has(key)) {
        removedRows.push(value as T);
      }
    });

    // Add all rows in nullIDUpdatedRows to addedRows
    nullIDUpdatedRows.forEach((row) => {
      addedRows.push(row as T);
    });

    return { addedRows, updatedRows, removedRows };
  } catch (error) {
    console.error("Error comparing CSV files:", error);
    return { addedRows: [], updatedRows: [], removedRows: [] };
  }
}
