import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    legend: React.CSSProperties;
    legendTitle: React.CSSProperties;

  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    legend?: React.CSSProperties;
    legendTitle?: React.CSSProperties;

  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    legend: true;
    legendTitle: true;
  }
}

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#338484',
    },
    secondary: {
      main: '#BA1B21',
    }
  },
  typography: {
    legend: {
      fontSize: '12px',
    },
    legendTitle: {
      fontSize: '12px',
      fontWeight: 'bold',
    },
  },
});

export default theme;
