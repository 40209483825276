import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useTranslation } from "react-i18next";
import { MosquitoGenus } from "../../../utils/mapUtils";

export type SelectedDatasetValue =
  | "susceptibility"
  | "intensity"
  | "mechanisms";

interface ControlledDataPointsRadioButtonsGroupProps {
  value: SelectedDatasetValue;
  onChange: (value: "susceptibility" | "intensity" | "mechanisms") => void;
  onFilterChange?: () => void;
  mosquitoGenus: MosquitoGenus;
}

export default function ControlledDataPointsRadioButtonsGroup({
  value,
  onChange,
  onFilterChange,
  mosquitoGenus,
}: ControlledDataPointsRadioButtonsGroupProps) {
  const { t } = useTranslation();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value as SelectedDatasetValue);
    if (onFilterChange) {
      onFilterChange();
    }
  };

  return (
    <FormControl>
      <FormLabel id="controlled-data-points-radio-buttons-group">
        {t("mapPage.filterDrawer.dataPoints.title")}
      </FormLabel>
      <RadioGroup
        aria-labelledby="controlled-data-points-radio-buttons-group"
        name="controlled-data-points-radio-buttons-group"
        value={value}
        onChange={handleChange}
      >
        <FormControlLabel
          value="susceptibility"
          control={<Radio />}
          label={t("mapPage.filterDrawer.dataPoints.susceptibility")}
        />
        {mosquitoGenus === "anopheles" && (
          <FormControlLabel
            value="intensity"
            control={<Radio />}
            label={t("mapPage.filterDrawer.dataPoints.intensity")}
          />
        )}
        <FormControlLabel
          value="mechanisms"
          control={<Radio />}
          label={t("mapPage.filterDrawer.dataPoints.mechanisms")}
        />
      </RadioGroup>
    </FormControl>
  );
}
