import * as React from "react";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";

interface InsecticideTypeFilterProps {
  insecticideClass: string[];
  insecticideTypeOptions: Record<string, string[]>;
  insecticideType: string[];
  onInsecticideTypeChange: (newInsecticideType: string[]) => void;
  onFilterChange?: () => void;
}

export default function InsecticideTypeFilter({
  insecticideClass,
  insecticideTypeOptions,
  insecticideType,
  onInsecticideTypeChange,
  onFilterChange,
}: InsecticideTypeFilterProps) {
  const { t } = useTranslation();

  const handleChange = (newValue: string | string[]) => {
    onInsecticideTypeChange(newValue as string[]);
  };

  const combinedOptions = Array.from(new Set((insecticideClass.length > 0 ? insecticideClass : Object.keys(insecticideTypeOptions))
  .map(classKey => insecticideTypeOptions[classKey] || []) // Access options for each class or all classes if insecticideClass is empty
  .flat() // Flatten the array of arrays into a single array
  .filter(option => option !== 'NA' && option !== 'Na'))) // Filter out 'NA' and 'Na', then remove duplicates
  .sort((a, b) => a.localeCompare(b)); // Sort alphabetically

  return (
    <FormControl fullWidth>
      <FormLabel id="insecticide-type-group" sx={{ paddingBottom: 2 }}>
        {t("mapPage.filterDrawer.insecticideType.title")}
      </FormLabel>
      <Box>
        <Autocomplete
          fullWidth={true}
          multiple
          id="insecticide-type-filter"
          value={insecticideType}
          onChange={(event, newValue) => {
            handleChange(newValue);
            if (onFilterChange) {
              onFilterChange();
            }
          }}
          options={combinedOptions}          
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip label={option} {...getTagProps({ index })} />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("mapPage.filterDrawer.insecticideType.title")}
            />
          )}
        />
      </Box>
    </FormControl>
  );
}