// MapSpeedDial.tsx
import React from "react";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import LayersOutlinedIcon from "@mui/icons-material/LayersOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import SimCardDownloadOutlinedIcon from "@mui/icons-material/SimCardDownloadOutlined";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import CheckIcon from "@mui/icons-material/Check";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { FeatureCollection, Geometry, GeoJsonProperties } from "geojson";
import Papa from "papaparse";
import {
  ParseResults,
  createGeoJsonData,
  parseAndValidateCSV,
} from "../../utils/customDataCSVImport";
import { MosquitoGenus } from "../../utils/mapUtils";
import "./MapSpeedDial.css";
import { Map } from "mapbox-gl";

interface MapSpeedDialProps {
  dataLoaded: boolean;
  mosquitoGenus: MosquitoGenus;
  geoJsonCustomData: FeatureCollection<Geometry, GeoJsonProperties>;
  setGeoJsonCustomData: (
    geoJsonCustomData: FeatureCollection<Geometry, GeoJsonProperties>
  ) => void;
  clearGeoJsonCustomData: () => void;
  setErrorMessage: (message: string) => void;
  setOpenSnackbar: (open: boolean) => void;
  map: React.MutableRefObject<Map | null>;
  selectedLayer: string | null;
  setSelectedLayer: (layer: string | null) => void;
}

export default function MapSpeedDial(props: MapSpeedDialProps) {
  const theme = useTheme();
  const { t } = useTranslation();

  const fileInputRef = React.useRef<HTMLInputElement>(null);

  const [openSpeedDial, setOpenSpeedDial] = React.useState(false);
  const handleSpeedDialClick = () => {
    setOpenSpeedDial((prevOpen) => !prevOpen);
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [endemicityAnchorEl, setEndemicityAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const openEndemicityMenu = Boolean(endemicityAnchorEl);
  const handleEndemicityClick = (event: React.MouseEvent<HTMLElement>) => {
    setEndemicityAnchorEl(event.currentTarget);
  };
  const handleEndemicityClose = () => {
    setEndemicityAnchorEl(null);
  };

  const { selectedLayer, setSelectedLayer } = props;

  const instructionsFile =
    props.mosquitoGenus === "anopheles"
      ? "IRMapper_anopheles_template_guidance.pdf"
      : "IRMapper_aedes_template_guidance.pdf";
  const templateFile =
    props.mosquitoGenus === "anopheles"
      ? "IRMapper_anopheles_template.csv"
      : "IRMapper_aedes_template.csv";

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0];
      if (!file) return;
      Papa.parse(file, {
        header: true,
        complete: function (results: ParseResults) {
          try {
            parseAndValidateCSV(results, props.mosquitoGenus);
            const geoJsonCustomData = createGeoJsonData(
              results,
              props.mosquitoGenus
            );
            props.setGeoJsonCustomData(geoJsonCustomData);
          } catch (error: unknown) {
            console.error("Error creating GeoJSON data:", error);
            if (error instanceof Error) {
              props.setErrorMessage(error.message);
              props.setOpenSnackbar(true);
            }
          } finally {
            event.target.value = "";
          }
        },
      });
    }
  };

  const toggleLayerVisibility = (layerId: string, zoomExtent: number) => {
    const map = props.map.current;
    if (map) {
      const visibility = map.getLayoutProperty(layerId, "visibility");
      if (visibility === "visible") {
        map.setLayoutProperty(layerId, "visibility", "none");
        setSelectedLayer(null);
      } else {
        if (selectedLayer) {
          map.setLayoutProperty(selectedLayer, "visibility", "none");
        }
        map.setLayoutProperty(layerId, "visibility", "visible");
        map.setZoom(zoomExtent);
        setSelectedLayer(layerId);
      }
    }
  };

  return (
    <>
      <SpeedDial
        ariaLabel="SpeedDial additional actions"
        sx={{
          position: "absolute",
          top: theme.spacing(2),
          left: theme.spacing(2),
        }}
        icon={
          <SpeedDialIcon
            icon={<MoreHorizOutlinedIcon />}
            openIcon={<CloseOutlinedIcon />}
          />
        }
        direction={"right"}
        onClick={handleSpeedDialClick}
        open={openSpeedDial}
        FabProps={{ color: "secondary" }}
      >
        <SpeedDialAction
          key="Upload your own data"
          icon={<CloudUploadOutlinedIcon />}
          tooltipTitle={t("mapPage.speedDialActions.uploadCustomData")}
          tooltipPlacement="bottom"
          onClick={(event) => {
            event.stopPropagation();
            handleClickMenu(event);
            setOpenSpeedDial(true);
          }}
          aria-label="upload-custom-data-menu-button"
          aria-controls={openMenu ? "upload-custom-data-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={openMenu ? "true" : undefined}
        />
        {props.geoJsonCustomData.features.length > 0 && props.dataLoaded && (
          <SpeedDialAction
            key="Clear Custom Data"
            icon={
              <DeleteForeverOutlinedIcon sx={{ color: "secondary.main" }} />
            }
            tooltipTitle={t("mapPage.speedDialActions.clearCustomData")}
            onClick={(event) => {
              event.stopPropagation();
              props.clearGeoJsonCustomData();
            }}
          />
        )}
        <SpeedDialAction
          key="Download analysis-ready datasets"
          icon={<CloudDownloadOutlinedIcon />}
          tooltipTitle={t("mapPage.speedDialActions.downloadData")}
          tooltipPlacement="bottom"
          onClick={(event) => {
            event.stopPropagation();
            setOpenSpeedDial(true);
          }}
          FabProps={{ href: "./Anopheles_AnalysisReadyDatasets.zip" }}
        />
        <SpeedDialAction
          key="Display endemicity layers"
          icon={<LayersOutlinedIcon />}
          tooltipTitle={t("mapPage.speedDialActions.endemicityLayers")}
          tooltipPlacement="bottom"
          onClick={(event) => {
            event.stopPropagation();
            handleEndemicityClick(event);
            setOpenSpeedDial(true);
          }}
        />
      </SpeedDial>
      {anchorEl && (
        <Menu
          id="upload-custom-data-menu"
          anchorEl={anchorEl}
          open={openMenu}
          onClose={handleCloseMenu}
          MenuListProps={{
            "aria-labelledby": "upload-custom-data-menu-button",
          }}
        >
          <a
            href={instructionsFile}
            download
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <MenuItem onClick={handleCloseMenu}>
              <ListItemIcon>
                <HelpOutlineOutlinedIcon />
              </ListItemIcon>
              <ListItemText>
                {t(
                  "mapPage.speedDialActions.uploadCustomDataMenuItems.instructions"
                )}
              </ListItemText>
            </MenuItem>
          </a>
          <a
            href={templateFile}
            download
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <MenuItem onClick={handleCloseMenu}>
              <ListItemIcon>
                <SimCardDownloadOutlinedIcon />
              </ListItemIcon>
              <ListItemText>
                {t(
                  "mapPage.speedDialActions.uploadCustomDataMenuItems.downloadTemplate"
                )}
              </ListItemText>
            </MenuItem>
          </a>
          <MenuItem
            onClick={() => {
              handleCloseMenu();
              fileInputRef.current?.click();
            }}
          >
            <ListItemIcon>
              <UploadFileOutlinedIcon />
            </ListItemIcon>
            <ListItemText>
              {t(
                "mapPage.speedDialActions.uploadCustomDataMenuItems.uploadData"
              )}
            </ListItemText>
          </MenuItem>
        </Menu>
      )}
      {endemicityAnchorEl && (
        <Menu
          id="endemicity-layers-menu"
          anchorEl={endemicityAnchorEl}
          open={openEndemicityMenu}
          onClose={handleEndemicityClose}
          MenuListProps={{
            "aria-labelledby": "endemicity-layers-menu-button",
          }}
        >
          {props.mosquitoGenus === "anopheles" && [
            <Typography key="vector-occurrence" variant="subtitle2" sx={{ padding: theme.spacing(1) }}>
              Vector Occurrence
            </Typography>,
            <MenuItem
              key="anopheles_funestus"
              onClick={() => toggleLayerVisibility("anopheles_funestus", 6.0)}
              selected={selectedLayer === "anopheles_funestus"}
            >
              {selectedLayer === "anopheles_funestus" && <CheckIcon />}
              <ListItemText>Anopheles funestus s.l.</ListItemText>
            </MenuItem>,
            <MenuItem
              key="anopheles_gambiae"
              onClick={() => toggleLayerVisibility("anopheles_gambiae", 6.0)}
              selected={selectedLayer === "anopheles_gambiae"}
            >
              {selectedLayer === "anopheles_gambiae" && <CheckIcon />}
              <ListItemText>Anopheles gambiae s.l.</ListItemText>
            </MenuItem>,
            <Divider key="divider1" />,
            <Typography key="resistance-layers" variant="subtitle2" sx={{ padding: theme.spacing(1) }}>
              Resistance Layers
            </Typography>,
            <MenuItem
              key="pyrethroid_susceptibility"
              onClick={() =>
                toggleLayerVisibility("pyrethroid_susceptibility", 5.0)
              }
              selected={selectedLayer === "pyrethroid_susceptibility"}
            >
              {selectedLayer === "pyrethroid_susceptibility" && <CheckIcon />}
              <ListItemText>Probability of pyrethroid susceptibility</ListItemText>
            </MenuItem>,
          ]}
        </Menu>
      )}
      <input
        type="file"
        id="file-upload"
        ref={fileInputRef}
        className="hidden-file-input"
        accept=".csv"
        onChange={handleFileUpload}
        title="Upload CSV file"
      />
    </>
  );
}
