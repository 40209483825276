import { FeatureCollection, Point, Feature } from "geojson";

export interface AedesRawSamplingPoint {
  id: string;
  country: string;
  locality: string;
  latitude: string;
  longitude: string;
  collection_Year_Start: number;
  collection_Year_End: number;
  vector_Species: string;
  vector_Developmental_Stage: string;
  iR_Test_Method: string;
  chemical_Class: string;
  chemical_Type: string;
  insecticide_Dosage: string;
  iraC_MoA: string;
  iraC_MoA_Code: string;
  ir_Test_NumExposed: string;
  resistance_Ratio: string;
  iR_Test_Mortality: string;
  resistance_Status: string;
  synergist_Type: string;
  synergist_Dosage: string;
  synergist_Test_Mortality: string;
  iR_Mechanism_Name: string;
  mutation_Frequency: string;
  iR_Mechanism_Status: string;
  reference_Type: string;
  reference_Name: string;
  url: string;
}

const API_URL =
  process.env.NODE_ENV === "production" ? process.env.REACT_APP_API_URL : "";

const normalize = (point: AedesRawSamplingPoint): Feature<Point> => ({
  type: "Feature",
  properties: {
    id: point.id,
    country: point.country,
    locality: point.locality,
    latitude: parseFloat(point.latitude),
    longitude: parseFloat(point.longitude),
    collectionStartYear: point.collection_Year_Start,
    collectionEndYear: point.collection_Year_End,
    vectorSpecies: point.vector_Species,
    vectorDevStage: point.vector_Developmental_Stage,
    testMethod: point.iR_Test_Method,
    chemicalClass: point.chemical_Class,
    chemicalType: point.chemical_Type,
    insecticideDosage: point.insecticide_Dosage,
    iracMoa: point.iraC_MoA,
    iracMoACode: point.iraC_MoA_Code,
    testNumExposed: point.ir_Test_NumExposed,
    resistanceRatio: point.resistance_Ratio,
    testMortality: point.iR_Test_Mortality,
    resistanceStatus: point.resistance_Status,
    synergistType: point.synergist_Type,
    synergistDosage: point.synergist_Dosage,
    synergistTestMortality: point.synergist_Test_Mortality,
    mechanismName: point.iR_Mechanism_Name,
    mutationFrequency: point.mutation_Frequency,
    mechanismDetectionStatus: point.iR_Mechanism_Status,
    referenceType: point.reference_Type,
    referenceName: point.reference_Name,
    url: point.url,
  },
  geometry: {
    type: "Point",
    coordinates: [parseFloat(point.longitude), parseFloat(point.latitude)],
  },
});

export const getAedesGeoJSONData = async (
  onProgressUpdate: (percentage: number) => void
): Promise<FeatureCollection> => {
  try {
    const response = await fetch(`${API_URL}/api/Aedes`);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    if (!response.body) {
      throw new Error("ReadableStream not supported in this browser.");
    }

    // Get the total size from the Content-Length header
    // const contentLength = response.headers.get('Content-Length');
    const contentLength = "8515947";
    const totalLength = contentLength ? parseInt(contentLength, 10) : 0;

    const reader = response.body.getReader();
    let receivedLength = 0; // received that many bytes at the moment
    let chunks = []; // array of received binary chunks (comprises the body)
    while (true) {
      const { done, value } = await reader.read();
      if (done) {
        break;
      }
      chunks.push(value);
      receivedLength += value.length;
      // Calculate the percentage
      const percentage = totalLength ? (receivedLength / totalLength) * 100 : 0;
      onProgressUpdate(percentage); // Call the callback with the current progress
    }

    // Concatenate chunks into single Uint8Array
    let chunksAll = new Uint8Array(receivedLength);
    let position = 0;
    for (let chunk of chunks) {
      chunksAll.set(chunk, position);
      position += chunk.length;
    }

    // Convert to string and then to JSON
    const resultString = new TextDecoder("utf-8").decode(chunksAll);
    const data = JSON.parse(resultString);

    if (!Array.isArray(data)) {
      throw new Error("Response data is not an array");
    }

    const features = data.map(normalize);

    return {
      type: "FeatureCollection",
      features: features,
    };
  } catch (error) {
    console.error("Failed to fetch sampling points:", error);
    return {
      type: "FeatureCollection",
      features: [],
    };
  }
};
