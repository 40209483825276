import * as React from "react";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";

interface IRMechanismNameFilterProps {
  IRMechanismNameOptions: string[];
  IRMechanismName: string[];
  onIRMechanismNameChange: (newIRMechanismName: string[]) => void;
  onFilterChange?: () => void;
}

export default function IRMechanismNameFilter({
  IRMechanismNameOptions,
  IRMechanismName,
  onIRMechanismNameChange,
  onFilterChange,
}: IRMechanismNameFilterProps) {
  const { t } = useTranslation();

  const handleChange = (newValue: string | string[]) => {
    onIRMechanismNameChange(newValue as string[]);
  };

  return (
    <FormControl fullWidth>
      <FormLabel id="IR-mechanism-name-group" sx={{ paddingBottom: 2 }}>
        {t("mapPage.filterDrawer.IRMechanismName.title")}
      </FormLabel>
      <Box>
        <Autocomplete
          fullWidth={true}
          multiple
          id="IR-mechanism-name-filter"
          value={IRMechanismName}
          onChange={(event, newValue) => {
            handleChange(newValue);
            if (onFilterChange) {
              onFilterChange();
            }
          }}
          options={IRMechanismNameOptions.filter(option => option !== 'NA' && option !== 'Na').sort((a, b) => a.localeCompare(b))}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip label={option} {...getTagProps({ index })} />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("mapPage.filterDrawer.IRMechanismName.title")}
            />
          )}
        />
      </Box>
    </FormControl>
  );
}