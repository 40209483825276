// pages/AedesPage.tsx
import React, { useState } from "react";
import FilterContainer from "../components/filter-container/FilterContainer";
import { CheckboxContextProvider } from "../components/filter-container/filters/StatusCheckboxContext";
import { SelectedDatasetValue } from "../components/filter-container/filters/ControlledDataPointsRadioButtonsGroup";
import IRMap from "../components/map-pages/IRMap";

const AedesPage = () => {
  const [selectedDatasetValue, setSelectedDatasetValue] =
    useState<SelectedDatasetValue>("susceptibility");

  const [collectionYearRange, setCollectionYearRange] = React.useState<
    number[]
  >([2000, new Date().getFullYear()]);

  const [countryOptions, setCountryOptions] = useState<string[]>([]);
  const [selectedCountries, setSelectedCountries] = React.useState<string[]>(
    []
  );

  const [vectorSpeciesOptions, setVectorSpeciesOptions] = useState<string[]>(
    []
  );
  const [selectedVectorSpecies, setSelectedVectorSpecies] = React.useState<
    string[]
  >([]);

  const [vectorDevStageOptions, setVectorDevStageOptions] = useState<string[]>([]);
  const [selectedVectorDevStage, setSelectedVectorDevStage] = React.useState<string[]>([]);

  const defaultTestMethods = React.useMemo(
    () => ({
      susceptibility: [
        "WHO tube assay",
        "WHO larval bioassay",
        "WHO bottle assay",
        "CDC bottle bioassay",
        "CDC intensity bioassay",
        "WHO tube assay with synergist",
      ],
      intensity: ["CDC intensity bioassay", "WHO intensity bioassay"],
      mechanisms: [
        "WHO tube assay with synergist",
        "CDC bottle bioassay with synergist",
        "PCR",
      ],
    }),
    []
  );

  const [testMethods, setTestMethods] = React.useState<string[]>(
    defaultTestMethods[selectedDatasetValue]
  );

  const [insecticideClassOptions, setInsecticideClassOptions] = useState<
    string[]
  >([]);
  const [selectedInsecticideClass, setSelectedInsecticideClass] =
    React.useState<string[]>([]);

  const [insecticideTypeOptions, setInsecticideTypeOptions] = useState<
  Record<string, string[]>
  >({});
  const [selectedInsecticideType, setSelectedInsecticideType] = React.useState<
    string[]
  >([]);

  const [IRACMoAOptions, setIRACMoAOptions] = useState<string[]>([]);
  const [selectedIRACMoA, setSelectedIRACMoA] = React.useState<string[]>([]);

  const [IRMechanismNameOptions, setIRMechanismNameOptions] = useState<
    string[]
  >([]);
  const [selectedIRMechanismName, setSelectedIRMechanismName] = React.useState<
    string[]
  >([]);

  React.useEffect(() => {
    setTestMethods(defaultTestMethods[selectedDatasetValue]);
  }, [defaultTestMethods, selectedDatasetValue]);

  const handleRadioButtonChange = (
    value: "susceptibility" | "intensity" | "mechanisms"
  ) => {
    setSelectedDatasetValue(value);
    setTestMethods(defaultTestMethods[value]);
  };

  const handleTestMethodsChange = (
    value: string[],
    dataset: SelectedDatasetValue
  ) => {
    setTestMethods(value);
  };
  return (
    <CheckboxContextProvider>
      <FilterContainer
        mosquitoGenus="aedes"
        selectedDatasetValue={selectedDatasetValue}
        onRadioButtonChange={handleRadioButtonChange}
        yearRange={collectionYearRange}
        onYearRangeChange={setCollectionYearRange}
        countryOptions={countryOptions}
        countries={selectedCountries}
        onCountriesChange={setSelectedCountries}
        vectorSpeciesOptions={vectorSpeciesOptions}
        vectorSpecies={selectedVectorSpecies}
        onVectorSpeciesChange={setSelectedVectorSpecies}
        vectorDevStageOptions={vectorDevStageOptions}
        vectorDevStage={selectedVectorDevStage}
        onVectorDevStageChange={setSelectedVectorDevStage}
        testMethods={testMethods}
        onTestMethodsChange={(newValue) =>
          handleTestMethodsChange(newValue, selectedDatasetValue)
        }
        insecticideClassOptions={insecticideClassOptions}
        insecticideClass={selectedInsecticideClass}
        onInsecticideClassChange={setSelectedInsecticideClass}
        insecticideTypeOptions={insecticideTypeOptions}
        insecticideType={selectedInsecticideType}
        onInsecticideTypeChange={setSelectedInsecticideType}
        IRACMoAOptions={IRACMoAOptions}
        IRACMoA={selectedIRACMoA}
        onIRACMoAChange={setSelectedIRACMoA}
        IRMechanismNameOptions={IRMechanismNameOptions}
        IRMechanismName={selectedIRMechanismName}
        onIRMechanismNameChange={setSelectedIRMechanismName}
      />
      <IRMap
        mosquitoGenus="aedes"
        selectedDatasetValue={selectedDatasetValue}
        collectionYearRange={collectionYearRange}
        selectedCountries={selectedCountries}
        onCountryOptionsChange={setCountryOptions}
        selectedVectorSpecies={selectedVectorSpecies}
        onVectorSpeciesOptionsChange={setVectorSpeciesOptions}
        selectedVectorDevStage={selectedVectorDevStage}
        onVectorDevStageOptionsChange={setVectorDevStageOptions}
        testMethods={testMethods}
        selectedInsecticideClass={selectedInsecticideClass}
        onInsecticideClassOptionsChange={setInsecticideClassOptions}
        selectedInsecticideType={selectedInsecticideType}
        onInsecticideTypeOptionsChange={setInsecticideTypeOptions}
        selectedIRACMoA={selectedIRACMoA}
        onIRACMoAOptionsChange={setIRACMoAOptions}
        selectedIRMechanismName={selectedIRMechanismName}
        onIRMechanismNameOptionsChange={setIRMechanismNameOptions}
      />
    </CheckboxContextProvider>
  );
};

export default AedesPage;
