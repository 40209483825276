import * as React from "react";
import { useTranslation } from "react-i18next";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import Pagination from "@mui/material/Pagination";
import { MosquitoGenus } from "../../utils/mapUtils";

interface PopupContentProps {
  features: mapboxgl.MapboxGeoJSONFeature[];
  mosquitoGenus: MosquitoGenus;
}

const PopupContent: React.FC<PopupContentProps> = ({ features, mosquitoGenus }) => {
  const { t } = useTranslation();
  const [page, setPage] = React.useState(1);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const sortedFeatures = features.sort((a, b) => {
    const yearA = a.properties?.collectionEndYear || 0;
    const yearB = b.properties?.collectionEndYear || 0;
    return yearB - yearA; // sort in descending order
  });

  return (
    <Box
      height={264}
      width={230}
      overflow={"hidden"}
      paddingY={1}
      paddingX={1}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Pagination
        count={sortedFeatures.length}
        page={page}
        onChange={handleChange}
        siblingCount={0}
        boundaryCount={1}
        size="small"
      />
      <Stack
        alignItems={"left"}
        spacing={1}
        paddingX={2}
        paddingY={2}
        height={"100%"}
        overflow={"auto"}
      >
        <TextField
          disabled
          id="country"
          label={t("mapPage.labels.country")}
          variant="standard"
          size="small"
          margin="dense"
          value={sortedFeatures[page - 1]?.properties?.country || "NA"}
        />
        <TextField
          disabled
          id="location"
          label={t("mapPage.labels.location")}
          variant="standard"
          size="small"
          margin="dense"
          value={sortedFeatures[page - 1]?.properties?.locality || "NA"}
        />
        <TextField
          disabled
          id="vector-species"
          label={t("mapPage.labels.vectorSpecies")}
          variant="standard"
          size="small"
          margin="dense"
          value={sortedFeatures[page - 1]?.properties?.vectorSpecies || "NA"}
        />
        {mosquitoGenus === "aedes" && (
          <TextField
            disabled
            id="vector-developmental-stage"
            label={t("mapPage.labels.vectorDevelopmentalStage")}
            variant="standard"
            size="small"
            margin="dense"
            value={sortedFeatures[page - 1]?.properties?.vectorDevStage || "NA"}
          />
        )}
        <TextField
          disabled
          id="collection-start"
          label={t("mapPage.labels.collectionStart")}
          variant="standard"
          size="small"
          margin="dense"
          value={
            sortedFeatures[page - 1]?.properties?.collectionStartYear || "NA"
          }
        />
        <TextField
          disabled
          id="collection-end"
          label={t("mapPage.labels.collectionEnd")}
          variant="standard"
          size="small"
          margin="dense"
          value={
            sortedFeatures[page - 1]?.properties?.collectionEndYear || "NA"
          }
        />
        <TextField
          disabled
          id="insecticide-class"
          label={t("mapPage.labels.insecticideClass")}
          variant="standard"
          size="small"
          margin="dense"
          value={sortedFeatures[page - 1]?.properties?.chemicalClass || "NA"}
        />
        <TextField
          disabled
          id="irac-moa-code"
          label={t("mapPage.labels.iRACMoACode")}
          variant="standard"
          size="small"
          margin="dense"
          value={sortedFeatures[page - 1]?.properties?.iracMoACode || "NA"}
        />
        <TextField
          disabled
          id="insecticide-type"
          label={t("mapPage.labels.insecticideType")}
          variant="standard"
          size="small"
          margin="dense"
          value={sortedFeatures[page - 1]?.properties?.chemicalType || "NA"}
        />
          <TextField
            disabled
            id="insecticide-dosage"
            label={t("mapPage.labels.insecticideDosage")}
            variant="standard"
            size="small"
            margin="dense"
            value={
              sortedFeatures[page - 1]?.properties?.insecticideDosage || "NA"
            }
          />
          <TextField
            disabled
            id="test-num-exposed"
            label={t("mapPage.labels.testNumExposed")}
            variant="standard"
            size="small"
            margin="dense"
            value={sortedFeatures[page - 1]?.properties?.testNumExposed || "NA"}
          />
        {mosquitoGenus === "aedes" && (
          <TextField
            disabled
            id="resistance-ratio"
            label={t("mapPage.labels.resistanceRatio")}
            variant="standard"
            size="small"
            margin="dense"
            value={
              sortedFeatures[page - 1]?.properties?.resistanceRatio || "NA"
            }
          />
        )}
        {mosquitoGenus === "aedes" && (        
          <TextField
          disabled
          id="synergist-type"
          label={t("mapPage.labels.synergistType")}
          variant="standard"
          size="small"
          margin="dense"
          value={sortedFeatures[page - 1]?.properties?.synergistType || "NA"}
        />
        )}

        <TextField
          disabled
          id="resistance-mechanism-name"
          label={t("mapPage.labels.resistanceMechanismName")}
          variant="standard"
          size="small"
          margin="dense"
          value={sortedFeatures[page - 1]?.properties?.mechanismName || "NA"}
        />
          <TextField
            disabled
            id="mutation-frequency"
            label={t("mapPage.labels.mutationFrequency")}
            variant="standard"
            size="small"
            margin="dense"
            value={
              sortedFeatures[page - 1]?.properties?.mutationFrequency || "NA"
            }
          />
        <TextField
          disabled
          id="resistance-mechanism-status"
          label={t("mapPage.labels.resistanceMechanismStatus")}
          variant="standard"
          size="small"
          margin="dense"
          value={
            sortedFeatures[page - 1]?.properties
              ?.mechanismDetectionStatus || "NA"
          }
        />
        <TextField
          disabled
          id="ir-test-method"
          label={t("mapPage.labels.iRTestMethod")}
          variant="standard"
          size="small"
          margin="dense"
          value={sortedFeatures[page - 1]?.properties?.testMethod || "NA"}
        />
        <TextField
          disabled
          id="mortality"
          label={t("mapPage.labels.mortality")}
          variant="standard"
          size="small"
          margin="dense"
          value={sortedFeatures[page - 1]?.properties?.testMortality || "NA"}
        />
        <TextField
          disabled
          id="reference"
          label={t("mapPage.labels.reference")}
          variant="standard"
          size="small"
          margin="dense"
          value={sortedFeatures[page - 1]?.properties?.referenceName || "NA"}
          multiline
          rows={4}
          InputProps={{
            endAdornment: sortedFeatures[page - 1]?.properties?.url ? (
              <IconButton
                aria-label="open"
                size="small"
                href={sortedFeatures[page - 1]?.properties?.url}
                target="_blank"
              >
                <OpenInNewOutlinedIcon fontSize="inherit" />
              </IconButton>
            ) : null,
            sx: {
              alignItems: "flex-start",
            },
          }}
        />
      </Stack>
    </Box>
  );
};

export default PopupContent;
