import React from "react";
import { MuiFileInput } from "mui-file-input";
import AttachFileIcon from "@mui/icons-material/AttachFile";

interface FileInputProps {
  value: File | null;
  onChange: (newFile: File | null) => void;
  error: boolean;
  helperText: string;
}

const FileInput: React.FC<FileInputProps> = ({
  value,
  onChange,
  error,
  helperText,
}) => {
  return (
    <MuiFileInput
      value={value}
      onChange={onChange}
      error={error}
      helperText={helperText}
      size="small"
      variant="outlined"
      placeholder="Upload updated CSV file here"
      autoComplete="off"
      InputProps={{
        inputProps: {
          accept: ".csv",
        },
        startAdornment: <AttachFileIcon />,
      }}
    />
  );
};

export default FileInput;
