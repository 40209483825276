import * as React from "react";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import HomeOutlined from "@mui/icons-material/HomeOutlined";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';


export default function HamburgerMenu() {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleNavigate = (path: string) => {
    handleClose();
    navigate(path);
  };
  const { t } = useTranslation();

  return (
    <div>
      <IconButton
        id="hamburger-menu-button"
        size="large"
        edge="start"
        color="inherit"
        aria-label="hamburger-menu-button"
        sx={{ mr: 2 }}
        aria-controls={open ? "hamburger-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id="hamburger-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "hamburger-menu-button",
        }}
      >
        <MenuItem onClick={() => handleNavigate('/')}>
          <ListItemIcon>
            <HomeOutlined />
          </ListItemIcon>
          <ListItemText>{t("mapPage.hamburgerMenu.menuItems.home")}</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => handleNavigate('/anopheles')}>
          <ListItemText
            primary={
              <>
                <i>Anopheles</i> {t("mapPage.hamburgerMenu.menuItems.map")}
              </>
            }
          />
        </MenuItem>
        <MenuItem onClick={() => handleNavigate('/aedes')}>
          <ListItemText
            primary={
              <>
                <i>Aedes</i> {t("mapPage.hamburgerMenu.menuItems.map")}
              </>
            }
          />
        </MenuItem>
      </Menu>
    </div>
  );
}
