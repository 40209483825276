import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { clarity } from 'react-microsoft-clarity';

const CookieBanner: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);

  const clarityID = "nmklnby9lg";

  useEffect(() => {
    const consent = localStorage.getItem("cookieConsent");
    if (!consent) {
      setIsVisible(true);
    } else if (consent === "accepted") {
      if (process.env.NODE_ENV === "production") {
        clarity.init(clarityID);
        clarity.consent();
      }    
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("cookieConsent", "accepted");
    setIsVisible(false);
    if (process.env.NODE_ENV === "production") {
      clarity.init(clarityID);
      clarity.consent();
    }
  };

  const handleDecline = () => {
    localStorage.setItem("cookieConsent", "declined");
    setIsVisible(false);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 0,
        width: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        color: "white",
        padding: "16px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        zIndex: 1050,
      }}
    >
      <Typography variant="body2">
        We use tracking cookies to understand how you use IR Mapper and help us
        improve it. Please accept cookies to help us improve.
      </Typography>
      <Box>
        <Button
          variant="contained"
          color="primary"
          onClick={handleAccept}
          sx={{ marginRight: "8px" }}
        >
          Accept
        </Button>
        <Button variant="contained" color="secondary" onClick={handleDecline}>
          Decline
        </Button>
      </Box>
    </Box>
  );
};

export default CookieBanner;
