import * as React from "react";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import { SelectedDatasetValue } from "./ControlledDataPointsRadioButtonsGroup";

interface IRTestMethodFilterProps {
  selectedDatasetValue: SelectedDatasetValue;
  testMethods: string[];
  onTestMethodsChange: (newTestMethod: string[]) => void;
  onFilterChange?: () => void;
}

export default function IRTestMethodFilter({
  selectedDatasetValue,
  testMethods,
  onTestMethodsChange,
  onFilterChange,
}: IRTestMethodFilterProps) {
  const { t } = useTranslation();

  const testMethodOptions = React.useMemo(
    () => ({
      susceptibility: [
        "CDC bottle bioassay",
        "CDC bottle bioassay with synergist",
        "CDC intensity bioassay",
        "Topical application",
        "WHO bottle assay",
        "WHO larval bioassay",
        "WHO larval bioassay with synergist",
        "WHO tube assay",
        "WHO tube assay with synergist",
      ],
      intensity: ["CDC intensity bioassay", "WHO intensity bioassay"],
      mechanisms: [
        "Biochemical assay",
        "CDC bottle bioassay with synergist",
        "Isoenzyme electrophoresis",
        "Microarray",
        "PCR",
        "Pyrosequencing",
        "SSOP-ELISA",
        "WHO larval bioassay with synergist",
        "WHO tube assay",
        "WHO tube assay with synergist",
      ],
    }),
    []
  );

  const handleChange = (newValue: string | string[]) => {
    onTestMethodsChange(newValue as string[]);
  };

  return (
    <FormControl fullWidth>
      <FormLabel id="test-methods-group" sx={{ paddingBottom: 2 }}>
        {t("mapPage.filterDrawer.testMethods.title")}
      </FormLabel>
      <Box>
        <Autocomplete
          fullWidth={true}
          multiple
          id="ir-test-methods-filter"
          value={testMethods}
          onChange={(event, newValue) => {
            handleChange(newValue);
            if (onFilterChange) {
              onFilterChange();
            }
          }}
          options={testMethodOptions[selectedDatasetValue].filter(option => option !== 'NA' && option !== 'Na').sort((a, b) =>
            a.localeCompare(b)
          )}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip label={option} {...getTagProps({ index })} />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("mapPage.filterDrawer.testMethods.title")}
            />
          )}
        />
      </Box>
    </FormControl>
  );
}
