import * as React from "react";
import * as ReactDOM from "react-dom/client";
import 'mapbox-gl/dist/mapbox-gl.css';
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import App from "./App";
import theme from "./theme";
import reportWebVitals from "./reportWebVitals";
import "./i18n/config.ts";

const rootElement = document.getElementById("root");
if (rootElement === null) throw new Error('Root container missing in index.html')
const root = ReactDOM.createRoot(rootElement!);

root.render(
  <ThemeProvider theme={theme}>
    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
    <CssBaseline />
    <App />
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
