import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Stack from "@mui/material/Stack";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import HamburgerMenu from "./components/app-bar/HamburgerMenu";
import Box from "@mui/material/Box";
import HelpMenu from "./components/app-bar/HelpMenu";
import AnophelesPage from "./pages/AnophelesPage";
import AedesPage from "./pages/AedesPage";
import AdminPage from "./pages/AdminPage";
import HomePage from "./pages/HomePage";
import CookieBanner from "./components/CookieBanner";

function MyAppBar() {
  const location = useLocation();
  const pageNameMap: { [key: string]: string } = {
    "/aedes": "Aedes",
    "/anopheles": "Anopheles",
    "/admin": "Admin",
  };
  const defaultPage = "Home";

  const keyFound = Object.keys(pageNameMap).find(key => location.pathname.includes(key));
  const pageName = keyFound ? pageNameMap[keyFound] : defaultPage;

  return (
    <AppBar position="static">
      <Toolbar>
        <HamburgerMenu />
        <Typography variant="overline">
          {`IR Mapper | ${pageName}`}
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <HelpMenu />
      </Toolbar>
    </AppBar>
  );
}

export default function App() {
  return (
    <Router>
      <Stack height={"100dvh"}>
        <MyAppBar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/anopheles" element={<AnophelesPage />} />
          <Route path="/aedes" element={<AedesPage />} />
          <Route path="/admin" element={<AdminPage />} />
        </Routes>
        <CookieBanner />
      </Stack>
    </Router>
  );
}
