// IRMapperLogo.tsx
import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const IRMapperLogo = () => {
  const theme = useTheme();

  return (
    <Stack
      direction={"row"}
      alignContent={"center"}
      alignItems={"center"}
      spacing={1}
      sx={{
        position: "absolute",
        bottom: theme.spacing(2),
        left: theme.spacing(2),
      }}
    >
      <Box
        component={"img"}
        sx={{ height: 30, width: 64 }}
        alt="IR Mapper logo"
        src="./irlogo1.png"
      />
      <Typography variant="subtitle2" height={26}>
        v. 3.0
      </Typography>
    </Stack>
  );
};

export default IRMapperLogo;