import * as React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import { useTranslation } from "react-i18next";
import { SelectedDatasetValue } from "./ControlledDataPointsRadioButtonsGroup";
import { MarkerLegend } from "../../map-pages/MapLegend";
import { StatusCheckboxContext } from "./StatusCheckboxContext";

interface StatusCheckboxProps {
  selectedDatasetValue: SelectedDatasetValue;
  onFilterChange?: () => void;
}

export default function StatusCheckboxGroup({
  selectedDatasetValue,
  onFilterChange,
}: StatusCheckboxProps) {
  const { t } = useTranslation();

  const checkboxContext = React.useContext(StatusCheckboxContext);

  if (!checkboxContext) {
    throw new Error(
      "StatusCheckboxGroup must be used within a CheckboxContext.Provider"
    );
  }

  const { confirmedResistance, setConfirmedResistance } = checkboxContext;
  const { possibleResistance, setPossibleResistance } = checkboxContext;
  const { susceptibilityResistance, setSusceptibilityResistance } =
    checkboxContext;
  const { highIntensity, setHighIntensity } = checkboxContext;
  const { moderateIntensity, setModerateIntensity } = checkboxContext;
  const { lowIntensity, setLowIntensity } = checkboxContext;
  const { detectedMechanism, setDetectedMechanism } = checkboxContext;
  const { notDetectedMechanism, setNotDetectedMechanism } = checkboxContext;

  return (
    <FormControl>
      <FormLabel id="status-checkbox-group">
        {t("mapPage.filterDrawer.status.title")}
      </FormLabel>
      {selectedDatasetValue === "susceptibility" && (
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={confirmedResistance}
                onChange={(event) => {
                  setConfirmedResistance(event.target.checked);
                  if (onFilterChange) {
                    onFilterChange();
                  }
                }}
              />
            }
            label={
              <MarkerLegend
                color="#d32f2f"
                label={t("mapPage.legend.susceptibility.confirmed")}
              />
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={possibleResistance}
                onChange={(event) => {
                  setPossibleResistance(event.target.checked);
                  if (onFilterChange) {
                    onFilterChange();
                  }
                }}
              />
            }
            label={
              <MarkerLegend
                color="#ffd54f"
                label={t("mapPage.legend.susceptibility.possible")}
              />
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={susceptibilityResistance}
                onChange={(event) => {
                  setSusceptibilityResistance(event.target.checked);
                  if (onFilterChange) {
                    onFilterChange();
                  }
                }}
              />
            }
            label={
              <MarkerLegend
                color="#2e7d32"
                label={t("mapPage.legend.susceptibility.susceptibility")}
              />
            }
          />
        </FormGroup>
      )}
      {selectedDatasetValue === "intensity" && (
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={highIntensity}
                onChange={(event) => {
                  setHighIntensity(event.target.checked);
                  if (onFilterChange) {
                    onFilterChange();
                  }
                }}
              />
            }
            label={
              <MarkerLegend
                color="#691616"
                label={t("mapPage.legend.intensity.highIntensity")}
              />
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={moderateIntensity}
                onChange={(event) => {
                  setModerateIntensity(event.target.checked);
                  if (onFilterChange) {
                    onFilterChange();
                  }
                }}
              />
            }
            label={
              <MarkerLegend
                color="#AF5F01"
                label={t("mapPage.legend.intensity.moderateIntensity")}
              />
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={lowIntensity}
                onChange={(event) => {
                  setLowIntensity(event.target.checked);
                  if (onFilterChange) {
                    onFilterChange();
                  }
                }}
              />
            }
            label={
              <MarkerLegend
                color="#C69B02"
                label={t("mapPage.legend.intensity.lowIntensity")}
              />
            }
          />
        </FormGroup>
      )}
      {selectedDatasetValue === "mechanisms" && (
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={detectedMechanism}
                onChange={(event) => {
                  setDetectedMechanism(event.target.checked);
                  if (onFilterChange) {
                    onFilterChange();
                  }
                }}
              />
            }
            label={
              <MarkerLegend
                color="#4527A0"
                label={t("mapPage.legend.mechanisms.detected")}
              />
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={notDetectedMechanism}
                onChange={(event) => {
                  setNotDetectedMechanism(event.target.checked);
                  if (onFilterChange) {
                    onFilterChange();
                  }
                }}
              />
            }
            label={
              <MarkerLegend
                color="#64B5F6"
                label={t("mapPage.legend.mechanisms.notDetected")}
              />
            }
          />
        </FormGroup>
      )}
    </FormControl>
  );
}
