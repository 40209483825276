import React, { useEffect, useMemo, useState } from "react";
import mapboxgl from "mapbox-gl";
import { getAnophelesGeoJSONData } from "../../AnophelesData";
import { getAedesGeoJSONData } from "../../AedesData";
import { SelectedDatasetValue } from "../filter-container/filters/ControlledDataPointsRadioButtonsGroup";
import { FeatureCollection, Geometry, GeoJsonProperties } from "geojson";
import {
  setLayerVisibility,
  createFilter,
  setLayerFilter,
  addLayersToMap,
  extractOptionsHelper,
  MosquitoGenus,
  extractChemicalOptions,
} from "../../utils/mapUtils";
import {
  handleClick,
  handleMouseEnter,
  handleMouseLeave,
} from "../../utils/mapEventHandlers";
import { StatusCheckboxContext } from "../filter-container/filters/StatusCheckboxContext";
import { anophelesLayersConfig } from "../../utils/config/layersConfig";
import { defautlLayerFilters } from "../../utils/config/defaultLayerFilters";

interface MapDataProps {
  map: React.MutableRefObject<mapboxgl.Map | null>;
  mosquitoGenus: MosquitoGenus;
  selectedDatasetValue: SelectedDatasetValue;
  collectionYearRange: number[];
  onCountryOptionsChange: (countryOptions: string[]) => void;
  selectedCountries: string[];
  onVectorSpeciesOptionsChange: (vectorSpeciesOptions: string[]) => void;
  selectedVectorSpecies: string[];
  onVectorDevStageOptionsChange: (vectorDevStageOptions: string[]) => void;
  selectedVectorDevStage: string[];
  testMethods: string[];
  onInsecticideClassOptionsChange: (insecticideClassOptions: string[]) => void;
  selectedInsecticideClass: string[];
  onInsecticideTypeOptionsChange: (insecticideTypeOptions: Record<string, string[]>) => void;
  selectedInsecticideType: string[];
  onIRACMoAOptionsChange: (IRACMoAOptions: string[]) => void;
  selectedIRACMoA: string[];
  onIRMechanismNameOptionsChange: (IRMechanismNameOptions: string[]) => void;
  selectedIRMechanismName: string[];
  setMapLoaded: (loaded: boolean) => void;
  setDataLoaded: (loaded: boolean) => void;
  geoJsonCustomData: FeatureCollection;
  setDownloadProgress: (progress: number) => void;
}

const MapData: React.FC<MapDataProps> = ({
  map,
  mosquitoGenus,
  selectedDatasetValue,
  collectionYearRange,
  onCountryOptionsChange,
  selectedCountries,
  onVectorSpeciesOptionsChange,
  selectedVectorSpecies,
  onVectorDevStageOptionsChange,
  selectedVectorDevStage,
  testMethods,
  onInsecticideClassOptionsChange,
  selectedInsecticideClass,
  onInsecticideTypeOptionsChange,
  selectedInsecticideType,
  onIRACMoAOptionsChange,
  selectedIRACMoA,
  onIRMechanismNameOptionsChange,
  selectedIRMechanismName,
  setMapLoaded,
  setDataLoaded,
  geoJsonCustomData,
  setDownloadProgress,
}) => {
  const initialGeoJsonData: FeatureCollection<Geometry, GeoJsonProperties> = {
    type: "FeatureCollection",
    features: [],
  };
  const [geoJsonData, setGeoJsonData] =
    useState<FeatureCollection<Geometry, GeoJsonProperties>>(
      initialGeoJsonData
    );
  const [dataLoaded, setLocalDataLoaded] = useState(false);

  const statusCheckboxContext = React.useContext(StatusCheckboxContext);

  if (!statusCheckboxContext) {
    throw new Error("MapData must be used within a CheckboxContext.Provider");
  }

  // Mapping object for layer visibility
  const layerVisibilityByDatasetValue: {
    [key: string]: { [key: string]: boolean };
  } = useMemo(
    () => ({
      susceptibility: {
        "confirmed-resistance": statusCheckboxContext.confirmedResistance,
        "possible-resistance": statusCheckboxContext.possibleResistance,
        "susceptible-resistance":
          statusCheckboxContext.susceptibilityResistance,
        "custom-confirmed-resistance":
          statusCheckboxContext.confirmedResistance,
        "custom-possible-resistance": statusCheckboxContext.possibleResistance,
        "custom-susceptible-resistance":
          statusCheckboxContext.susceptibilityResistance,
      },
      intensity: {
        "resistance-intensity-high": statusCheckboxContext.highIntensity,
        "resistance-intensity-moderate":
          statusCheckboxContext.moderateIntensity,
        "resistance-intensity-low": statusCheckboxContext.lowIntensity,
        "custom-resistance-intensity-high": statusCheckboxContext.highIntensity,
        "custom-resistance-intensity-moderate":
          statusCheckboxContext.moderateIntensity,
        "custom-resistance-intensity-low": statusCheckboxContext.lowIntensity,
      },
      mechanisms: {
        "resistance-mechanism-detected":
          statusCheckboxContext.detectedMechanism,
        "resistance-mechanism-not-detected":
          statusCheckboxContext.notDetectedMechanism,
        "custom-resistance-mechanism-detected":
          statusCheckboxContext.detectedMechanism,
        "custom-resistance-mechanism-not-detected":
          statusCheckboxContext.notDetectedMechanism,
      },
    }),
    [statusCheckboxContext]
  );

  const addDataToMap = React.useCallback(
    function () {
      if (!map.current) {
        throw new Error("Map is not defined");
      }

      try {
        // Add default data to map
        if (map.current && !map.current?.getSource("irmapper-data")) {
          map.current?.addSource("irmapper-data", {
            type: "geojson",
            data: geoJsonData,
            generateId: true,
          });

          addLayersToMap(
            map.current,
            "irmapper-data",
            anophelesLayersConfig,
            false
          );
        }
        // Add custom data to map
        if (map.current && !map.current?.getSource("custom-data")) {
          map.current.addSource("custom-data", {
            type: "geojson",
            data: geoJsonCustomData,
            generateId: true,
          });
          addLayersToMap(
            map.current,
            "custom-data",
            anophelesLayersConfig,
            true
          );
        }
      } catch (error) {
        console.error("Failed to add data to map:", error);
      }

      // Set dataLoaded to true after the data has been added to the map
      setLocalDataLoaded(true);
      setDataLoaded(true);
    },
    [geoJsonData, geoJsonCustomData, map, setDataLoaded]
  );

  // 1. Fetch data from the API only once and store the result in the geoJsonData state.
  useEffect(() => {
    try {
      let fetchedGeoJsonData =
        mosquitoGenus === "anopheles"
          ? getAnophelesGeoJSONData(setDownloadProgress)
          : getAedesGeoJSONData(setDownloadProgress);
      fetchedGeoJsonData.then((data) => {
        setGeoJsonData(data);
      });
    } catch (error) {
      console.error("Failed to fetch data from API:", error);
    }
  }, [mosquitoGenus, setDownloadProgress]);

  // 2. Run addDataToMap with geoJsonData as an argument only once or whenever geoJsonData changes.
  useEffect(() => {
    if (geoJsonData.features.length > 0) {
      addDataToMap();
    }
  }, [geoJsonData, addDataToMap]);

  // 3. When geoJsonCustomData changes, setData on source and trigger map repaint.
  useEffect(() => {
    if (map.current && map.current.getSource("custom-data")) {
      (map.current.getSource("custom-data") as mapboxgl.GeoJSONSource).setData(
        geoJsonCustomData
      );
      map.current.triggerRepaint();
    }
  }, [map, geoJsonCustomData]);

  // useEffect hook for filtering
  useEffect(() => {
    // Update the visibility of the layers based on the selected dataset
    if (dataLoaded) {
      const intervalId = setInterval(() => {
        if (map.current?.isStyleLoaded()) {
          clearInterval(intervalId); // Clear the interval once the map style is loaded

          // Set the visibility of all layers to 'none'
          Object.entries(layerVisibilityByDatasetValue).forEach(
            ([datasetValue, layerVisibility]) => {
              Object.keys(layerVisibility).forEach((layerId) => {
                map.current?.setLayoutProperty(layerId, "visibility", "none");
              });
            }
          );

          const layerVisibility =
            layerVisibilityByDatasetValue[selectedDatasetValue];

          // Set the visibility of the layers associated with the selected dataset
          Object.entries(layerVisibility).forEach(([layerId, isVisible]) => {
            const visibility = isVisible ? "visible" : "none";
            map.current?.setLayoutProperty(layerId, "visibility", visibility);
          });

          // Set individual layer visibility based on the checkbox context
          Object.entries(layerVisibility).forEach(([layerId, isVisible]) => {
            if (map.current) {
              setLayerVisibility(map.current, layerId, isVisible);
            }
          });

          const layerIds = Object.keys(layerVisibility);

          const countryFilter = createFilter(selectedCountries, "country");
          const vectorSpeciesFilter = createFilter(
            selectedVectorSpecies,
            "vectorSpecies"
          );
          const vectorDevStageFilter = createFilter(
            selectedVectorDevStage,
            "vectorDevStage"
          );
          const testMethodFilter = createFilter(testMethods, "testMethod");
          const insecticideClassFilter = createFilter(
            selectedInsecticideClass,
            "chemicalClass"
          );
          const insecticideTypeFilter = createFilter(
            selectedInsecticideType,
            "chemicalType"
          );
          const IRACMoAFilter = createFilter(selectedIRACMoA, "iracMoACode");
          const IRMechanismNameFilter = createFilter(
            selectedIRMechanismName,
            "mechanismName"
          );

          // Set the filter for the layers
          layerIds.forEach((layerId) => {
            if (map.current !== null) {
              setLayerFilter(
                map.current,
                layerId,
                defautlLayerFilters[layerId],
                collectionYearRange,
                testMethodFilter,
                countryFilter,
                vectorSpeciesFilter,
                vectorDevStageFilter,
                insecticideClassFilter,
                insecticideTypeFilter,
                IRACMoAFilter,
                IRMechanismNameFilter
              );
            }
          });

          let hoveredDefaultFeatureId: string | number | undefined | null =
            null;
          let hoveredCustomFeatureId: string | number | undefined | null = null;

          map.current?.on("click", layerIds, (e): void => {
            if (map.current) {
              handleClick(map.current, "irmapper-data", mosquitoGenus, e);
              handleClick(map.current, "custom-data", mosquitoGenus, e);
            }
          });

          map.current?.on("mouseenter", layerIds, (e) => {
            if (map.current) {
              hoveredDefaultFeatureId = handleMouseEnter(
                map.current,
                "irmapper-data",
                e,
                hoveredDefaultFeatureId
              );
              hoveredCustomFeatureId = handleMouseEnter(
                map.current,
                "custom-data",
                e,
                hoveredCustomFeatureId
              );
            }
          });

          map.current?.on("mouseleave", layerIds, () => {
            if (map.current) {
              hoveredDefaultFeatureId = handleMouseLeave(
                map.current,
                "irmapper-data",
                hoveredDefaultFeatureId
              );
              hoveredCustomFeatureId = handleMouseLeave(
                map.current,
                "custom-data",
                hoveredCustomFeatureId
              );
            }
          });
        }
      }, 100);

      // Clear the interval on unmount
      return () => clearInterval(intervalId);
    }
  }, [
    map,
    mosquitoGenus,
    selectedDatasetValue,
    dataLoaded,
    statusCheckboxContext,
    layerVisibilityByDatasetValue,
    collectionYearRange,
    testMethods,
    selectedCountries,
    selectedVectorSpecies,
    selectedVectorDevStage,
    selectedInsecticideClass,
    selectedInsecticideType,
    selectedIRACMoA,
    selectedIRMechanismName,
  ]);

  useEffect(() => {
    // Extract options for auto-complete filters
    const countryOptions = extractOptionsHelper(
      geoJsonData,
      geoJsonCustomData,
      "country"
    );
    onCountryOptionsChange(countryOptions);

    const vectorSpeciesOptions = extractOptionsHelper(
      geoJsonData,
      geoJsonCustomData,
      "vectorSpecies"
    );
    onVectorSpeciesOptionsChange(vectorSpeciesOptions);

    const vectorDevStageOptions = extractOptionsHelper(
      geoJsonData,
      geoJsonCustomData,
      "vectorDevStage"
    );
    onVectorDevStageOptionsChange(vectorDevStageOptions);

    const insecticideClassOptions = extractOptionsHelper(
      geoJsonData,
      geoJsonCustomData,
      "chemicalClass"
    );
    onInsecticideClassOptionsChange(insecticideClassOptions);

    const insecticideTypeOptions = extractChemicalOptions(geoJsonData);
    onInsecticideTypeOptionsChange(insecticideTypeOptions);

    const IRACMoAOptions = extractOptionsHelper(
      geoJsonData,
      geoJsonCustomData,
      "iracMoACode"
    );
    onIRACMoAOptionsChange(IRACMoAOptions);

    const IRMechanismNameOptions = extractOptionsHelper(
      geoJsonData,
      geoJsonCustomData,
      "mechanismName"
    );
    onIRMechanismNameOptionsChange(IRMechanismNameOptions);
  }, [
    geoJsonData,
    geoJsonCustomData,
    onCountryOptionsChange,
    onVectorSpeciesOptionsChange,
    onVectorDevStageOptionsChange,
    onInsecticideClassOptionsChange,
    onInsecticideTypeOptionsChange,
    onIRACMoAOptionsChange,
    onIRMechanismNameOptionsChange,
  ]);

  // useEffect hook for map load event
  useEffect(() => {
    const currentMap = map.current;

    if (!currentMap) {
      console.error("Map is not defined");
      return;
    }

    if (currentMap) {
      currentMap.on("load", () => {
        setMapLoaded(true);
      });
    }

    // Clean up on unmount
    return () => {
      if (currentMap) {
        currentMap.off("load", () => {
          setMapLoaded(false);
        });
      }
    };
  }, [map, setMapLoaded]);

  return null;
};

export default MapData;
