import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import React from "react";


interface FeatureProps {
  icon: React.ReactNode;
  title: string;
  description: string;
}

const Feature = ({icon, title, description}: FeatureProps) => {
    const theme = useTheme();

    const coloredIcon = React.cloneElement(icon as React.ReactElement, { style: { color: theme.palette.success.main } });

  return (
    <Stack direction={"row"} spacing={"12px"} alignItems={"flex-start"}>
        {coloredIcon}
        <Stack direction={"column"} width={"244px"} spacing={1} alignItems={"flex-start"}>
            <Typography variant="h6">{title}</Typography>
            <Typography variant="body1">{description}</Typography>
        </Stack>
    </Stack>
  );
};

export default Feature;
