import * as React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import FilterDrawer from "./FilterDrawer";
import { grey } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import { SelectedDatasetValue } from "./filters/ControlledDataPointsRadioButtonsGroup";
import { StatusCheckboxContext } from "./filters/StatusCheckboxContext";
import { MosquitoGenus } from "../../utils/mapUtils";

interface FilterContainerProps {
  mosquitoGenus: MosquitoGenus;
  selectedDatasetValue: SelectedDatasetValue;
  onRadioButtonChange: (value: SelectedDatasetValue) => void;
  yearRange: number[];
  onYearRangeChange: (newYearRange: number[]) => void;
  countryOptions: string[];
  countries: string[];
  onCountriesChange: (newCountry: string[]) => void;
  vectorSpeciesOptions: string[];
  vectorSpecies: string[];
  onVectorSpeciesChange: (newVectorSpecies: string[]) => void;
  vectorDevStageOptions: string[];
  vectorDevStage: string[];
  onVectorDevStageChange: (newVectorSpecies: string[]) => void;
  testMethods: string[];
  onTestMethodsChange: (newTestMethod: string[]) => void;
  insecticideClassOptions: string[];
  insecticideClass: string[];
  onInsecticideClassChange: (newInsecticideClass: string[]) => void;
  insecticideTypeOptions: Record<string, string[]>;
  insecticideType: string[];
  onInsecticideTypeChange: (newInsecticideType: string[]) => void;
  IRACMoAOptions: string[];
  IRACMoA: string[];
  onIRACMoAChange: (newIRACMoA: string[]) => void;
  IRMechanismNameOptions: string[];
  IRMechanismName: string[];
  onIRMechanismNameChange: (newIRMechanismName: string[]) => void;
}

export default function FilterContainer(props: FilterContainerProps) {
  const { t } = useTranslation();

  const [open, setOpen] = React.useState(false);
  const [isFilterApplied, setFilterApplied] = React.useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const applyFilter = () => {
    setFilterApplied(true);
  };

  const checkboxContext = React.useContext(StatusCheckboxContext);
  if (!checkboxContext) {
    throw new Error(
      "FilterContainer must be used within a CheckboxContext.Provider"
    );
  }
  const { resetStatusCheckboxes } = checkboxContext;

  const clearFilter = () => {
    // Reset the radio button selection
    props.onRadioButtonChange("susceptibility");
    // Reset the status checkboxes
    resetStatusCheckboxes();
    // Reset the year range
    const defaultYearRange = [2000, new Date().getFullYear()];
    props.onYearRangeChange(defaultYearRange);
    //Reset the country selection
    props.onCountriesChange([]);
    // Reset the vector species selection
    props.onVectorSpeciesChange([]);
    // Reset the vector development stage selection
    props.onVectorDevStageChange([]);
    // Reset the insecticide class selection
    props.onInsecticideClassChange([]);
    // Reset the insecticide type selection
    props.onInsecticideTypeChange([]);
    // Reset the IRAC MoA selection
    props.onIRACMoAChange([]);
    // Reset the IR Mechanism Name selection
    props.onIRMechanismNameChange([]);

    setFilterApplied(false); // Hide the "Clear Filter" button
  };

  return (
    <Box height={68} py={2} px={3} bgcolor={grey.A100}>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"flex-start"}
        spacing={2}
      >
        <Button
          variant="contained"
          size="medium"
          startIcon={<FilterAltOutlinedIcon />}
          onClick={toggleDrawer(true)}
        >
          {t("mapPage.filterButton")}
        </Button>
        {isFilterApplied && ( // Conditionally render the "Clear Filter" button
          <Button variant="text" size="medium" onClick={clearFilter}>
            {t("mapPage.clearFilterButton")}
          </Button>
        )}
        <FilterDrawer
          mosquitoGenus={props.mosquitoGenus}
          selectedDatasetValue={props.selectedDatasetValue}
          open={open}
          toggleDrawer={toggleDrawer}
          onRadioButtonChange={props.onRadioButtonChange}
          onFilterChange={applyFilter}
          yearRange={props.yearRange}
          onYearRangeChange={props.onYearRangeChange}
          countryOptions={props.countryOptions}
          countries={props.countries}
          onCountriesChange={props.onCountriesChange}
          vectorSpeciesOptions={props.vectorSpeciesOptions}
          vectorSpecies={props.vectorSpecies}
          onVectorSpeciesChange={props.onVectorSpeciesChange}
          vectorDevStageOptions={props.vectorDevStageOptions}
          vectorDevStage={props.vectorDevStage}
          onVectorDevStageChange={props.onVectorDevStageChange}
          testMethods={props.testMethods}
          onTestMethodsChange={props.onTestMethodsChange}
          insecticideClassOptions={props.insecticideClassOptions}
          insecticideClass={props.insecticideClass}
          onInsecticideClassChange={props.onInsecticideClassChange}
          insecticideTypeOptions={props.insecticideTypeOptions}
          insecticideType={props.insecticideType}
          onInsecticideTypeChange={props.onInsecticideTypeChange}
          IRACMoAOptions={props.IRACMoAOptions}
          IRACMoA={props.IRACMoA}
          onIRACMoAChange={props.onIRACMoAChange}
          IRMechanismNameOptions={props.IRMechanismNameOptions}
          IRMechanismName={props.IRMechanismName}
          onIRMechanismNameChange={props.onIRMechanismNameChange}
        />
      </Stack>
    </Box>
  );
}
