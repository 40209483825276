import * as React from "react";
import IconButton from "@mui/material/IconButton";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import PictureAsPdfOutlined from "@mui/icons-material/PictureAsPdfOutlined";
import { useTranslation } from "react-i18next";

export default function HelpMenu() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { t } = useTranslation();

  return (
    <div>
      <IconButton
        id="help-menu-button"
        size="small"
        color="inherit"
        aria-label="help-menu-button"
        aria-controls={open ? "help-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <HelpOutlineOutlinedIcon />
      </IconButton>
      <Menu
        id="help-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "help-menu-button",
        }}
      >
        <a
          href="IR_Mapper_Navigation_Guide_EN.pdf"
          download
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <PictureAsPdfOutlined />
            </ListItemIcon>
            <ListItemText>{t("mapPage.helpMenu.menuItems.downloadUserGuide")} (EN)</ListItemText>
          </MenuItem>
        </a>
        <a
          href="IR_Mapper_Navigation_Guide_FR.pdf"
          download
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <PictureAsPdfOutlined />
            </ListItemIcon>
            <ListItemText>{t("mapPage.helpMenu.menuItems.downloadUserGuide")} (FR)</ListItemText>
          </MenuItem>
        </a>
      </Menu>
    </div>
  );
}
