// pages/AdminPage.tsx
import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import {
  downloadCSVFromJsonAPI,
  triggerCSVDownload,
} from "../utils/downloadDatabaseCSV";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Papa from "papaparse";
import { useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import {
  compareCSVs,
  IRMapperCSVRow,
  parseAndValidateIRMapperCSV,
  ParseResults,
} from "../utils/iRMapperDataCSVImport";
import DownloadCSVStep from "../components/admin-page/DownloadCSVStep";
import EditCSVStep from "../components/admin-page/EditCSVStep";
import PushChangesStep from "../components/admin-page/PushChangesStep";
import { updateDatabase } from "../utils/databaseUpdate";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const anophelesSteps = [
  "Download Anopheles CSV",
  "Update CSV",
  "Push changes to Anopheles database",
];

const aedesSteps = [
  "Download Aedes CSV",
  "Update CSV",
  "Push changes to Aedes database",
];

const AdminPage = () => {
  const [tab, setTab] = useState(0);
  const [activeStepAnopheles, setActiveStepAnopheles] = useState(0);
  const [activeStepAedes, setActiveStepAedes] = useState(0);
  const [anophelesFile, setAnophelesFile] = useState<File | null>(null);
  const [aedesFile, setAedesFile] = useState<File | null>(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [fileInputError, setFileInputError] = useState(false);
  const [fileInputHelperText, setFileInputHelperText] = useState("");
  const [addedRows, setAddedRows] = useState<IRMapperCSVRow[]>([]);
  const [removedRows, setRemovedRows] = useState<IRMapperCSVRow[]>([]);
  const [updatedRows, setUpdatedRows] = useState<IRMapperCSVRow[]>([]);

  const steps = tab === 0 ? anophelesSteps : aedesSteps;
  const getActiveStep = () =>
    tab === 0 ? activeStepAnopheles : activeStepAedes;
  const setActiveStep = (step: number) => {
    if (tab === 0) {
      setActiveStepAnopheles(step);
    } else {
      setActiveStepAedes(step);
    }
  };

  const handleCloseSnackbar = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleTabChange = (event: React.SyntheticEvent, newTab: number) => {
    setTab(newTab);
  };

  const handleNext = () => {
    setActiveStep(getActiveStep() + 1);
  };

  const handleBack = () => {
    setActiveStep(getActiveStep() - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setAnophelesFile(null);
    setAedesFile(null);
  };

  const handleAnophelesFileChange = (newFile: File | null) => {
    setAnophelesFile(newFile);
    setFileInputError(!newFile);
  };

  const handleAedesFileChange = (newFile: File | null) => {
    setAedesFile(newFile);
    setFileInputError(!newFile);
  };

  const handleCSVUpload = async () => {
    const fileToUpload = tab === 0 ? anophelesFile : aedesFile;
    try {
      if (fileToUpload) {
        const originalCSV = await downloadCSVFromJsonAPI(
          tab === 0 ? "anopheles" : "aedes"
        );
        const updatedCSV = await fileToUpload.text();

        Papa.parse(fileToUpload, {
          complete: async (results: ParseResults) => {
            try {
              await parseAndValidateIRMapperCSV(
                results,
                tab === 0 ? "anopheles" : "aedes"
              );
              const comparisonResult = await compareCSVs(
                originalCSV,
                updatedCSV
              );
              setAddedRows(comparisonResult.addedRows);
              setRemovedRows(comparisonResult.removedRows);
              setUpdatedRows(comparisonResult.updatedRows);

              setActiveStep(getActiveStep() + 1);
            } catch (error) {
              console.error("Error during CSV processing:", error);
              if (error instanceof Error) {
                setErrorMessage(error.message);
                setOpenSnackbar(true);
              }
            }
          },
          header: true,
          skipEmptyLines: true,
        });
      } else {
        setFileInputError(true);
        setFileInputHelperText(
          "No file selected. Please select a CSV file to upload."
        );
        throw new Error(
          "No file selected. Please select a CSV file to upload."
        );
      }
    } catch (error: unknown) {
      console.error("Error parsing CSV file:", error);
      if (error instanceof Error) {
        setErrorMessage(error.message);
        setOpenSnackbar(true);
      }
    }
  };

  const handleDatabaseUpdate = async () => {
    try {
      await updateDatabase(
        tab === 0 ? "anopheles" : "aedes",
        addedRows,
        removedRows,
        updatedRows
      );
      setActiveStep(getActiveStep() + 1);
    } catch (error) {
      console.error("Failed to update database:", error);
      if (error instanceof Error) {
        setErrorMessage(error.message);
        setOpenSnackbar(true);
      }
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 4 }}>
        <Tabs value={tab} onChange={handleTabChange} aria-label="Admin tabs">
          <Tab label="Update Anopheles data" {...a11yProps(0)} />
          <Tab label="Update Aedes data" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <Stepper activeStep={getActiveStep()} sx={{ m: 4 }}>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {getActiveStep() === 0 && (
        <DownloadCSVStep
          tab={tab}
          triggerCSVDownload={triggerCSVDownload}
          downloadCSVFromJsonAPI={downloadCSVFromJsonAPI}
        />
      )}
      {getActiveStep() === 1 && (
        <EditCSVStep
          tab={tab}
          anophelesFile={anophelesFile}
          aedesFile={aedesFile}
          fileInputError={fileInputError}
          fileInputHelperText={fileInputHelperText}
          handleAnophelesFileChange={handleAnophelesFileChange}
          handleAedesFileChange={handleAedesFileChange}
        />
      )}
      {getActiveStep() === 2 && (
        <PushChangesStep
          addedRows={addedRows}
          removedRows={removedRows}
          updatedRows={updatedRows}
        />
      )}
      {getActiveStep() === steps.length ? (
        <React.Fragment>
          <Box sx={{ m: 4 }}>
            <Alert severity="success">
              <AlertTitle>
                <i>{tab === 0 ? "Anopheles" : "Aedes"}</i> database was updated
                successfully
              </AlertTitle>
              Press RESET to start the process again.
            </Alert>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", m: 4 }}>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Box sx={{ display: "flex", flexDirection: "row", m: 4 }}>
            <Button
              color="inherit"
              disabled={getActiveStep() === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              {getActiveStep() === steps.length - 1 ? "Cancel" : "Back"}
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button
              disabled={
                getActiveStep() === 2 &&
                addedRows.length === 0 &&
                removedRows.length === 0 &&
                updatedRows.length === 0
              }
              onClick={async () => {
                if (getActiveStep() === 1) {
                  handleCSVUpload();
                } else if (getActiveStep() === 2) {
                  handleDatabaseUpdate();
                } else {
                  handleNext();
                }
              }}
            >
              {getActiveStep() === 2 ? "Update database" : "Next"}
            </Button>
          </Box>
        </React.Fragment>
      )}
      <Snackbar
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        sx={{ width: "95%" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="error"
          sx={{ width: "100%" }}
        >
          <AlertTitle>Error</AlertTitle>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AdminPage;
