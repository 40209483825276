import { LayerConfig } from "../mapUtils";

// Configuration object for layers
export const anophelesLayersConfig: LayerConfig[] = [
    {
      id: "susceptible-resistance",
      color: "#2e7d32",
      custom: false,
    },
    {
      id: "possible-resistance",
      color: "#ffd54f",
      custom: false,
    },
    {
      id: "confirmed-resistance",
      color: "#d32f2f",
      custom: false,
    },
    {
      id: "resistance-intensity-low",
      color: "#C69B02",
      custom: false,
    },
    {
      id: "resistance-intensity-moderate",
      color: "#AF5F01",
      custom: false,
    },
    {
      id: "resistance-intensity-high",
      color: "#691616",
      custom: false,
    },
    {
      id: "resistance-mechanism-not-detected",
      color: "#64B5F6",
      custom: false,
    },
    {
      id: "resistance-mechanism-detected",
      color: "#4527A0",
      custom: false,
    },
    {
      id: "custom-susceptible-resistance",
      color: "#2e7d32",
      custom: true,
    },
    {
      id: "custom-possible-resistance",
      color: "#ffd54f",
      custom: true,
    },
    {
      id: "custom-confirmed-resistance",
      color: "#d32f2f",
      custom: true,
    },
    {
      id: "custom-resistance-intensity-low",
      color: "#C69B02",
      custom: true,
    },
    {
      id: "custom-resistance-intensity-moderate",
      color: "#AF5F01",
      custom: true,
    },
    {
      id: "custom-resistance-intensity-high",
      color: "#691616",
      custom: true,
    },
    {
      id: "custom-resistance-mechanism-not-detected",
      color: "#64B5F6",
      custom: true,
    },
    {
      id: "custom-resistance-mechanism-detected",
      color: "#4527A0",
      custom: true,
    },
  ];

  export const aedesLayersConfig: LayerConfig[] = [
    {
      id: "susceptible-resistance",
      color: "#2e7d32",
      custom: false,
    },
    {
      id: "possible-resistance",
      color: "#ffd54f",
      custom: false,
    },
    {
      id: "confirmed-resistance",
      color: "#d32f2f",
      custom: false,
    },
    {
      id: "resistance-mechanism-not-detected",
      color: "#64B5F6",
      custom: false,
    },
    {
      id: "resistance-mechanism-detected",
      color: "#4527A0",
      custom: false,
    },
    {
      id: "custom-susceptible-resistance",
      color: "#2e7d32",
      custom: true,
    },
    {
      id: "custom-possible-resistance",
      color: "#ffd54f",
      custom: true,
    },
    {
      id: "custom-confirmed-resistance",
      color: "#d32f2f",
      custom: true,
    },
    {
      id: "custom-resistance-mechanism-not-detected",
      color: "#64B5F6",
      custom: true,
    },
    {
      id: "custom-resistance-mechanism-detected",
      color: "#4527A0",
      custom: true,
    },
  ];