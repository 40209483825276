import mapboxgl from "mapbox-gl";
import { defautlLayerFilters } from "./config/defaultLayerFilters";
import { FeatureCollection } from "geojson";

export type FilterType = (string | boolean | any[] | null | undefined)[];

export type MosquitoGenus = "anopheles" | "aedes";

export interface LayerConfig {
  id: string;
  color: string;
  custom: boolean;
}
const addLayer = (
  map: mapboxgl.Map,
  sourceId: string,
  id: string,
  filter: any[],
  color: string,
  custom: boolean
) => {
  if (map && !map.getLayer(id)) {
    if (custom) {
      map.loadImage("./triangle-marker.png", (error, image) => {
        if (error) throw error;
        if (image) {
          if (!map.hasImage("custom-marker")) {
            map.addImage("custom-marker", image, { sdf: true });
          }
        } else {
          console.error("Image could not be loaded");
        }
      });

      map.addLayer({
        id,
        type: "symbol",
        source: sourceId,
        filter,
        layout: {
          visibility: "none",
          "icon-allow-overlap": true,
          "icon-image": "custom-marker",
          "icon-size": ["interpolate", ["linear"], ["zoom"], 2.29, 1, 5, 1.4],
        },
        paint: {
          "icon-color": color,
          "icon-halo-color": [
            "case",
            ["boolean", ["feature-state", "hover"], false],
            "#0288D1",
            "#333333",
          ],
          "icon-halo-width": [
            "case",
            ["boolean", ["feature-state", "hover"], false],
            4,
            1,
          ],
        },
      });
    } else {
      map.addLayer({
        id,
        type: "circle",
        source: sourceId,
        filter,
        layout: {
          visibility: "none",
        },
        paint: {
          "circle-radius": ["interpolate", ["linear"], ["zoom"], 2.29, 3, 5, 5],
          "circle-color": color,
          "circle-stroke-color": [
            "case",
            ["boolean", ["feature-state", "hover"], false],
            "#0288D1",
            "#333333",
          ],
          "circle-stroke-width": [
            "case",
            ["boolean", ["feature-state", "hover"], false],
            4,
            0.25,
          ],
          "circle-stroke-opacity": [
            "case",
            ["boolean", ["feature-state", "hover"], false],
            0.3,
            1,
          ],
        },
      });
    }
  }
};

export const addLayersToMap = (
  map: mapboxgl.Map,
  sourceId: string,
  layerConfigs: LayerConfig[],
  custom: boolean
): void => {
  layerConfigs.forEach((config: LayerConfig) => {
    if (config.custom === custom) {
      addLayer(
        map,
        sourceId,
        config.id,
        defautlLayerFilters[config.id],
        config.color,
        config.custom
      );
    }
  });
};

export const setLayerVisibility = (
  map: mapboxgl.Map,
  layerId: string,
  isVisible: boolean
) => {
  map.setLayoutProperty(layerId, "visibility", isVisible ? "visible" : "none");
};

export const createFilter = (items: string[], key: string): FilterType => {
  if (items.length === 0) {
    return [];
  }
  const expressions = items.map((item) => ["==", ["get", key], item]);
  const filter = ["any", ...expressions];
  return filter;
};

export const setLayerFilter = (
  map: mapboxgl.Map,
  layerId: string,
  initialFilter: FilterType,
  collectionYearRange: number[],
  testMethodFilter: FilterType,
  countryFilter: FilterType,
  vectorSpeciesFilter: FilterType,
  vectorDevStageFilter: FilterType,
  insecticideClassFilter: FilterType,
  insecticideTypeFilter: FilterType,
  IRACMoAFilter: FilterType,
  IRMechanismNameFilter: FilterType
) => {
  const newFilter = [
    "all",
    initialFilter,
    [">=", ["get", "collectionStartYear"], collectionYearRange[0]],
    ["<=", ["get", "collectionEndYear"], collectionYearRange[1]],
  ];

  if (testMethodFilter.length > 0) {
    newFilter.push(testMethodFilter);
  }

  if (countryFilter.length > 0) {
    newFilter.push(countryFilter);
  }

  if (vectorSpeciesFilter.length > 0) {
    newFilter.push(vectorSpeciesFilter);
  }

  if (insecticideClassFilter.length > 0) {
    newFilter.push(insecticideClassFilter);
  }

  if (insecticideTypeFilter.length > 0) {
    newFilter.push(insecticideTypeFilter);
  }

  if (IRACMoAFilter.length > 0) {
    newFilter.push(IRACMoAFilter);
  }

  if (IRMechanismNameFilter.length > 0) {
    newFilter.push(IRMechanismNameFilter);
  }

  if (vectorDevStageFilter.length > 0) {
    newFilter.push(vectorDevStageFilter);
  }

  map.setFilter(layerId, newFilter);
};

const extractOptions = (
  geoJsonData: FeatureCollection,
  key: string
): string[] => {
  return Array.from(
    new Set(
      geoJsonData.features.map(
        (feature: any) => feature.properties && feature.properties[key]
      )
    )
  );
};

// Helper function to extract options
export const extractOptionsHelper = (
  geoJsonData: FeatureCollection,
  geoJsonCustomData: FeatureCollection,
  key: string
) => {
  const options = [
    ...extractOptions(geoJsonData, key),
    ...extractOptions(geoJsonCustomData, key),
  ];
  return Array.from(new Set(options));
};

export const extractChemicalOptions = (
  geoJsonData: FeatureCollection
): Record<string, string[]> => {
  const chemicalClassMap: Map<string, Set<string>> = new Map();

  geoJsonData.features.forEach((feature) => {
    const properties = feature.properties;
    if (properties) {
      const { chemicalClass, chemicalType } = properties;
      if (chemicalClass && chemicalType) {
        if (!chemicalClassMap.has(chemicalClass)) {
          chemicalClassMap.set(chemicalClass, new Set());
        }
        chemicalClassMap.get(chemicalClass)?.add(chemicalType);
      }
    }
  });

  // Convert Map to the desired object structure
  const result: Record<string, string[]> = {};
  chemicalClassMap.forEach((types, className) => {
    result[className] = Array.from(types);
  });

  return result;
};
