// CheckboxContext.tsx
import React from "react";

interface StatusCheckboxContextProps {
  confirmedResistance: boolean;
  setConfirmedResistance: (value: boolean) => void;
  possibleResistance: boolean;
  setPossibleResistance: (value: boolean) => void;
  susceptibilityResistance: boolean;
  setSusceptibilityResistance: (value: boolean) => void;
  highIntensity: boolean;
  setHighIntensity: (value: boolean) => void;
  moderateIntensity: boolean;
  setModerateIntensity: (value: boolean) => void;
  lowIntensity: boolean;
  setLowIntensity: (value: boolean) => void;
  detectedMechanism: boolean;
  setDetectedMechanism: (value: boolean) => void;
  notDetectedMechanism: boolean;
  setNotDetectedMechanism: (value: boolean) => void;
  resetStatusCheckboxes: () => void;
}

export const StatusCheckboxContext = React.createContext<
  StatusCheckboxContextProps | undefined
>(undefined);

export const CheckboxContextProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {

  const [confirmedResistance, setConfirmedResistance] = React.useState(true);
  const [possibleResistance, setPossibleResistance] = React.useState(true);
  const [susceptibilityResistance, setSusceptibilityResistance] = React.useState(true);
  const [highIntensity, setHighIntensity] = React.useState(true);
  const [moderateIntensity, setModerateIntensity] = React.useState(true);
  const [lowIntensity, setLowIntensity] = React.useState(true);
  const [detectedMechanism, setDetectedMechanism] = React.useState(true);
  const [notDetectedMechanism, setNotDetectedMechanism] = React.useState(true);

  const resetStatusCheckboxes = () => {
    setConfirmedResistance(true);
    setPossibleResistance(true);
    setSusceptibilityResistance(true);
    setHighIntensity(true);
    setModerateIntensity(true);
    setLowIntensity(true);
    setDetectedMechanism(true);
    setNotDetectedMechanism(true);
  };

  return (
    <StatusCheckboxContext.Provider
      value={{
        confirmedResistance,
        setConfirmedResistance,
        possibleResistance,
        setPossibleResistance,
        susceptibilityResistance,
        setSusceptibilityResistance,
        highIntensity,
        setHighIntensity,
        moderateIntensity,
        setModerateIntensity,
        lowIntensity,
        setLowIntensity,
        detectedMechanism,
        setDetectedMechanism,
        notDetectedMechanism,
        setNotDetectedMechanism,
        resetStatusCheckboxes,
      }}
    >
      {children}
    </StatusCheckboxContext.Provider>
  );
};