import * as React from "react";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useTranslation } from "react-i18next";
import Slider from "@mui/material/Slider";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

interface CollectionYearRangeProps {
  yearRange: number[];
  onYearRangeChange: (newYearRange: number[]) => void;
  onFilterChange?: () => void;
}

export default function CollectionYearRange({
  yearRange,
  onYearRangeChange,
  onFilterChange,
}: CollectionYearRangeProps) {
  const { t } = useTranslation();

  const MAX = new Date().getFullYear();
  const MIN = 1954;

  const handleChange = (newValue: number | number[]) => {
    onYearRangeChange(newValue as number[]);
  };

  return (
    <FormControl>
      <FormLabel id="collection-year-group" sx={{ paddingBottom: 4 }}>
        {t("mapPage.filterDrawer.collectionYearRange.title")}
      </FormLabel>
      <Box>
        <Slider
          getAriaLabel={(index) =>
            t("mapPage.filterDrawer.collectionYearRange.ariaLabel", { index })
          }
          value={yearRange}
          min={MIN}
          max={new Date().getFullYear()}
          onChange={(event, newValue) => {
            handleChange(newValue);
            if (onFilterChange) {
              onFilterChange();
            }
          }}
          valueLabelDisplay="auto"
          disableSwap
          getAriaValueText={(value) => value.toString()}
        />
        <Box
          sx={{ display: "flex", justifyContent: "space-between", padding: 0 }}
        >
          <Typography variant="body2">{MIN}</Typography>
          <Typography variant="body2">{MAX}</Typography>
        </Box>
      </Box>
    </FormControl>
  );
}
