interface JsonData {
    [key: string]: string | number | boolean;
}

const API_URL =
  process.env.NODE_ENV === "production" ? process.env.REACT_APP_API_URL : "";

  export async function downloadCSVFromJsonAPI(endpoint: string): Promise<string> {
    const response = await fetch(`${API_URL}/api/` + endpoint);
    const jsonData = await response.json();
    const csvData = jsonToCSV(jsonData);
    return csvData;
}

function jsonToCSV(jsonData: JsonData[]): string {
    if (jsonData.length === 0) {
        return '';
    }
   // Extract headers
   const headers = Object.keys(jsonData[0]).join('","');
   // Map each row to a CSV string, escaping and wrapping values in double quotes
   const rows = jsonData.map(row => {
       return Object.values(row).map(value => 
           `"${String(value).replace(/"/g, '""')}"` // Escape and wrap values
       ).join(',');
   }).join('\n');
   return `"${headers}"\n${rows}`; // Wrap headers in double quotes as well

}

export function triggerCSVDownload(csvData: string, filename: string): void {
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}