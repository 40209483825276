import React from "react";
import { Box, Typography, Button } from "@mui/material";
import SimCardDownloadOutlinedIcon from "@mui/icons-material/SimCardDownloadOutlined";

interface DownloadCSVStepProps {
    tab: number; // Assuming tab is a number. Adjust the type according to your needs.
    triggerCSVDownload: (csvData: string, filename: string) => void; // Adjust the function type according to what it actually does.
    downloadCSVFromJsonAPI: (endpoint: string) => Promise<string>; // Adjust the function type according to what it actually does.
  }

const DownloadCSVStep: React.FC<DownloadCSVStepProps> = ({
  tab,
  triggerCSVDownload,
  downloadCSVFromJsonAPI,
}) => {
  return (
    <Box
      sx={{
        mt: 8,
        mb: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Typography variant="body1" sx={{ mb: 2 }}>
        First, download the CSV file containing all the existing{" "}
        {tab === 0 ? <i>Anopheles</i> : <i>Aedes</i>} data.
      </Typography>

      <Typography variant="body1" sx={{ mb: 4 }}>
        When you're done, please press NEXT.
      </Typography>

      <Button
        variant="contained"
        color="primary"
        onClick={async () =>
          triggerCSVDownload(
            await downloadCSVFromJsonAPI(tab === 0 ? "anopheles" : "aedes"),
            tab === 0 ? "anophelesData.csv" : "aedesData.csv"
          )
        }
        endIcon={<SimCardDownloadOutlinedIcon />}
      >
        Download {tab === 0 ? "Anopheles" : "Aedes"} CSV
      </Button>
    </Box>
  );
};

export default DownloadCSVStep;
