import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";

const Headline = () => {
  const { t } = useTranslation();

  return (
  <Stack direction={"column"} spacing={2} maxWidth={"540px"}>
    <Typography variant="h4" textAlign={"center"}>{t("homePage.headline.title")}</Typography> 
    <Typography variant="body1" textAlign={"center"}>{t("homePage.headline.content")}</Typography>
  </Stack>
  ) ;
};

export default Headline;
