import { FilterType } from "../mapUtils";

export const defautlLayerFilters: { [key: string]: FilterType } = {
    "confirmed-resistance": [
      "==",
      ["get", "resistanceStatus"],
      "Confirmed resistance",
    ],
    "possible-resistance": [
      "==",
      ["get", "resistanceStatus"],
      "Possible resistance",
    ],
    "susceptible-resistance": [
      "==",
      ["get", "resistanceStatus"],
      "Susceptibility",
    ],
    "resistance-intensity-high": [
      "==",
      ["get", "resistanceStatus"],
      "High intensity resistance",
    ],
    "resistance-intensity-moderate": [
      "==",
      ["get", "resistanceStatus"],
      "Moderate intensity resistance",
    ],
    "resistance-intensity-low": [
      "==",
      ["get", "resistanceStatus"],
      "Low intensity resistance",
    ],
    "resistance-mechanism-detected": [
      "==",
      ["get", "mechanismDetectionStatus"],
      "Detected",
    ],
    "resistance-mechanism-not-detected": [
      "==",
      ["get", "mechanismDetectionStatus"],
      "Not detected",
    ],
    "custom-confirmed-resistance": [
      "==",
      ["get", "resistanceStatus"],
      "Confirmed resistance",
    ],
    "custom-possible-resistance": [
      "==",
      ["get", "resistanceStatus"],
      "Possible resistance",
    ],
    "custom-susceptible-resistance": [
      "==",
      ["get", "resistanceStatus"],
      "Susceptibility",
    ],
    "custom-resistance-intensity-high": [
      "==",
      ["get", "resistanceStatus"],
      "High intensity resistance",
    ],
    "custom-resistance-intensity-moderate": [
      "==",
      ["get", "resistanceStatus"],
      "Moderate intensity resistance",
    ],
    "custom-resistance-intensity-low": [
      "==",
      ["get", "resistanceStatus"],
      "Low intensity resistance",
    ],
    "custom-resistance-mechanism-detected": [
      "==",
      ["get", "mechanismDetectionStatus"],
      "Detected",
    ],
    "custom-resistance-mechanism-not-detected": [
      "==",
      ["get", "mechanismDetectionStatus"],
      "Not detected",
    ],
  };