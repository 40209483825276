import { Box } from "@mui/material";
import Feature from "./Feature";
import CheckOutlined from "@mui/icons-material/CheckOutlined";
import { useTranslation } from "react-i18next";

const FeaturesGrid = () => {
  const { t } = useTranslation();

  return (
    <Box
      minWidth={"200px"}
      maxWidth={"1216px"}
      sx={{
        display: "flex",
        justifyContent: "space-between",  // Distributes items evenly with no extra margin
        alignItems: "flex-start",
        gap: "24px 32px",
        flexWrap: "wrap",
        width: "100%",  // Ensures it spans the entire width of the container
        maxWidth: "1200px",  // Optional: controls the maximum width of the content area
        margin: "0 auto",  // Centers the content in the middle of the screen
        "& > *": {
          flex: "1 1 calc(33% - 32px)",  // Ensures three items per row
          minWidth: "250px",  // Optional: ensures a minimum width for items
        },
      }}
    >
      <Feature
        icon={<CheckOutlined />}
        title={t("homePage.features.DynamicVisualization.title")}
        description={t("homePage.features.DynamicVisualization.description")}
      />
      <Feature
        icon={<CheckOutlined />}
        title={t("homePage.features.StandardizedResults.title")}
        description={t("homePage.features.StandardizedResults.description")}
      />
      <Feature
        icon={<CheckOutlined />}
        title={t("homePage.features.CustomizableFilters.title")}
        description={t("homePage.features.CustomizableFilters.description")}
      />
      <Feature
        icon={<CheckOutlined />}
        title={t("homePage.features.EndemicityLayers.title")}
        description={t("homePage.features.EndemicityLayers.description")}
      />
      <Feature
        icon={<CheckOutlined />}
        title={t("homePage.features.ReliableDataSources.title")}
        description={t("homePage.features.ReliableDataSources.description")}
      />{" "}
      <Feature
        icon={<CheckOutlined />}
        title={t("homePage.features.DataReporting.title")}
        description={t("homePage.features.DataReporting.description")}
      />
    </Box>
  );
};

export default FeaturesGrid;
