import Box from "@mui/material/Box";
import { IRMapperCSVRow } from "../../utils/iRMapperDataCSVImport";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { DataGrid } from "@mui/x-data-grid/DataGrid";
import AddOutlined from "@mui/icons-material/AddOutlined";
import DeleteForeverOutlined from "@mui/icons-material/DeleteForeverOutlined";
import SyncAltOutlined from "@mui/icons-material/SyncAltOutlined";
import Typography from "@mui/material/Typography";

interface PushChangesStepProps {
  addedRows: IRMapperCSVRow[]; // Adjust the type according to your needs.
  removedRows: IRMapperCSVRow[]; // Adjust the type according to your needs.
  updatedRows: IRMapperCSVRow[]; // Adjust the type according to your needs.
}

const renderRowsTable = (rows: IRMapperCSVRow[]) => {
  if (rows.length === 0) return <p>No data</p>;

  const columns = Object.keys(rows[0]).map((key) => {
    const headerName = key.charAt(0).toUpperCase() + key.slice(1);
    return {
      field: key,
      headerName,
      width: 150,
    };
  });

  // Map rows to ensure each has a non-null 'id', using index as fallback
  const processedRows = rows.map((row, index) => ({
    ...row,
    id: row.id != null ? row.id : index, // Use row index as fallback id
  }));

  return (
    <DataGrid
      rows={processedRows}
      columns={columns}
      density="compact"
      initialState={{ pagination: { paginationModel: { pageSize: 5 } } }}
      pageSizeOptions={[5, 10, 20, 50, 100]}
      autoHeight
      sx={{ backgroundColor: "white" }}
    />
  );
};

const PushChangesStep: React.FC<PushChangesStepProps> = ({
  addedRows,
  removedRows,
  updatedRows,
}) => {
  return (
    <Box
      sx={{
        mt: 8,
        mb: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Typography variant="body1" sx={{ mb: 2, ml:4}}>
        Please review carefully the changes to be pushed to the database before
        pressing UPDATE DATABASE.
      </Typography>
      {addedRows.length > 0 && (
        <Alert
          severity="success"
          icon={<AddOutlined fontSize="inherit" />}
          sx={{ width: "80%", m: 2 }}
        >
          <AlertTitle>
            {addedRows.length} new data point(s) to be added
          </AlertTitle>
          {renderRowsTable(addedRows)}
        </Alert>
      )}
      {removedRows.length > 0 && (
        <Alert
          severity="error"
          icon={<DeleteForeverOutlined fontSize="inherit" />}
          sx={{ width: "80%", m: 2 }}
        >
          <AlertTitle>
            {removedRows.length} data point(s) to be removed
          </AlertTitle>
          {renderRowsTable(removedRows)}
        </Alert>
      )}
      {updatedRows.length > 0 && (
        <Alert
          severity="warning"
          icon={<SyncAltOutlined fontSize="inherit" />}
          sx={{ width: "80%", m: 2 }}
        >
          <AlertTitle>
            {updatedRows.length} existing data point(s) to be updated
          </AlertTitle>
          {renderRowsTable(updatedRows)}
        </Alert>
      )}
      {addedRows.length === 0 &&
        removedRows.length === 0 &&
        updatedRows.length === 0 && (
          <Alert severity="info" sx={{ width: "80%", m: 2 }}>
            <AlertTitle>No changes detected</AlertTitle>
            There are no new, removed, or updated data points to update in the
            database.
          </Alert>
        )}
    </Box>
  );
};
export default PushChangesStep;
