import React, { useEffect } from 'react';
import mapboxgl from 'mapbox-gl';
import { MAPBOX_ACCESS_TOKEN_ANOPHELES, MAPBOX_ACCESS_TOKEN_AEDES } from '../../config';
import { MosquitoGenus } from '../../utils/mapUtils';

interface MapInitProps {
  mapContainer: React.RefObject<HTMLDivElement>;
  map: React.MutableRefObject<mapboxgl.Map | null>;
  mosquitoGenus: MosquitoGenus;
}

const MapInit: React.FC<MapInitProps> = ({ mapContainer, map, mosquitoGenus }) => {
  useEffect(() => {
    if (map.current || !mapContainer.current) return; // initialize map only once

    try {
        //Initialize map
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/vestergaard/clwtfe94x018r01pn603v23f8',
        center: [15.9581, 0.3336],
        zoom: 2.29,
        accessToken: mosquitoGenus === 'anopheles' ? MAPBOX_ACCESS_TOKEN_ANOPHELES : MAPBOX_ACCESS_TOKEN_AEDES,        
        logoPosition: 'bottom-right',
      });

      // Add zoom controls to the map
      const nav = new mapboxgl.NavigationControl({
        showCompass: false,
        visualizePitch: false,
      });
      map.current.addControl(nav, 'top-right');
    } catch (error) {
      console.error('Failed to initialize map', error);
    }

    // Clean up on unmount
    return () => {
      map.current?.remove();
    };
  }, [mapContainer, map, mosquitoGenus]);

  return null;
};

export default MapInit;