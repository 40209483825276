import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Paper from "@mui/material/Paper";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import FileInput from "./FileInput";

interface EditCSVStepProps {
    tab: number; 
    anophelesFile: File | null;
    aedesFile: File | null;
    fileInputError: boolean;
    fileInputHelperText: string;
    handleAnophelesFileChange: (newFile: File | null) => void;
    handleAedesFileChange: (newFile: File | null) => void;
}

const EditCSVStep: React.FC<EditCSVStepProps> = (
    { tab, anophelesFile, aedesFile, fileInputError, fileInputHelperText, handleAnophelesFileChange, handleAedesFileChange }
) => {
    return (
      <Box
        sx={{
          mt: 2,
          mb: 6,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Paper elevation={0} sx={{ backgroundColor: "#ffffff" }}>
          <Box sx={{ m: 4 }}>
            <Alert
              severity="warning"
              icon={<WarningAmberOutlinedIcon fontSize="inherit" />}
            >
              <AlertTitle>Edit with caution</AlertTitle>
              <List>
                <ListItem>
                  <Box
                    component="span"
                    sx={{
                      display: "list-item",
                      listStyleType: "disc",
                      ml: 3,
                    }}
                  >
                    Edit the CSV file to update the existing <i>{tab === 0 ? "Anopheles" : "Aedes"}</i>{" "}
                    data.
                  </Box>
                </ListItem>
                <ListItem>
                  <Box
                    component="span"
                    sx={{
                      display: "list-item",
                      listStyleType: "disc",
                      ml: 3,
                    }}
                  >
                    Do NOT change the column headers, add or remove columns, or
                    change the order of the columns.
                  </Box>
                </ListItem>
                <ListItem>
                  {" "}
                  <Box
                    component="span"
                    sx={{
                      display: "list-item",
                      listStyleType: "disc",
                      ml: 3,
                    }}
                  >
                    Do NOT change the id column.
                  </Box>
                </ListItem>
                <ListItem>
                  <Box
                    component="span"
                    sx={{
                      display: "list-item",
                      listStyleType: "disc",
                      ml: 3,
                    }}
                  >
                    To add a new data point, add a new row at the end of the
                    file but do not fill the id column.
                  </Box>
                </ListItem>
                <ListItem>
                  <Box
                    component="span"
                    sx={{
                      display: "list-item",
                      listStyleType: "disc",
                      ml: 3,
                    }}
                  >
                    To remove an existing data point, remove the row from the
                    file.
                  </Box>
                </ListItem>
                <ListItem>
                  <Box
                    component="span"
                    sx={{
                      display: "list-item",
                      listStyleType: "disc",
                      ml: 3,
                    }}
                  >
                    Make sure to save the file as a CSV file.
                  </Box>
                </ListItem>
              </List>
            </Alert>
          </Box>
          <Box sx={{ m: 4 }}>
            <Alert
              severity="info"
              icon={<InfoOutlinedIcon fontSize="inherit" />}
            >
              <AlertTitle>Ready to upload?</AlertTitle>
              When you're done, upload the CSV file below and press NEXT.
            </Alert>
          </Box>
        </Paper>
        <FileInput
            value={tab === 0 ? anophelesFile : aedesFile}
            onChange={tab === 0 ? handleAnophelesFileChange : handleAedesFileChange}
            error={fileInputError}
            helperText={fileInputHelperText}
          />
      </Box>
    );
};

export default EditCSVStep;