import * as React from "react";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";

interface CountryFilterProps {
  countryOptions: string[];
  countries: string[];
  onCountriesChange: (newCountry: string[]) => void;
  onFilterChange?: () => void;
}

export default function CountryFilter({
  countryOptions,
  countries,
  onCountriesChange,
  onFilterChange,
}: CountryFilterProps) {
  const { t } = useTranslation();

  const handleChange = (newValue: string | string[]) => {
    onCountriesChange(newValue as string[]);
  };

  return (
    <FormControl>
      <FormLabel id="countries-group" sx={{ paddingBottom: 2 }}>
        {t("mapPage.filterDrawer.countries.title")}
      </FormLabel>
      <Box>
        <Autocomplete
          multiple
          id="countries-filter"
          value={countries}
          onChange={(event, newValue) => {
            handleChange(newValue);
            if (onFilterChange) {
              onFilterChange();
            }
          }}
          options={countryOptions.filter(option => option !== 'NA' && option !== 'Na').sort((a, b) => a.localeCompare(b))}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip label={option} {...getTagProps({ index })} />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("mapPage.filterDrawer.countries.title")}
            />
          )}
        />
      </Box>
    </FormControl>
  );
}
